import React, { useState } from "react";
import Terms from "pages/info/Terms";
import Privacy from "pages/info/Privacy";
import Onboard from "pages/onboard/index";
import ErrorPage from "pages/Error";
import AdminPage from "pages/admin/Page";
import AdminLogin from "pages/admin/Login";
import ScrollToTop from "components/ScrollToTop";
import { PageCacheProvider } from "contexts/pageCacheContext";
import { getAccessToken, getAdminAccessToken } from "utils/localStorageService";
import history from "utils/history";
import { configureLoggedSetter } from "utils/api";
import { configureLoggedSetter as configureAdminLoggedSetter } from "utils/adminApi";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import AlertTemplate from "components/AlertTemplate";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import DBConfig from "utils/DBConfig";
import { initDB } from "react-indexed-db";
import { GoogleOAuthProvider } from '@react-oauth/google';

initDB(DBConfig);

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  autoSessionTracking: true,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  environment: process.env.REACT_APP_SENTRY_ENV,
  ignoreErrors: ["ResizeObserver loop limit exceeded"],
});

const alertOptions = {
  position: positions.TOP_CENTER,
  timeout: 0,
  offset: ".5rem",
  transition: transitions.FADE,
  containerStyle: { marginTop: "4rem", zIndex: "100" },
};

const App = () => {
  const [logged, setLogged] = useState(getAccessToken() ? true : false);
  const [adminLogged, setAdminLogged] = useState(
    getAdminAccessToken() ? true : false
  );

  configureLoggedSetter(setLogged);
  configureAdminLoggedSetter(setAdminLogged);

  return (
    <Sentry.ErrorBoundary fallback={<ErrorPage />}>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <AlertProvider template={AlertTemplate} {...alertOptions}>
          <Router history={history}>
            <ScrollToTop />
            <Switch>
              <Route exact path="/terms">
                <Terms />
              </Route>
              <Route exact path="/privacy">
                <Privacy />
              </Route>
              <Route exact path="/talent/onboard">
                <Onboard />
              </Route>
              <Route path="/admin">
                {adminLogged ? (
                  <Switch>
                    <Route path="/admin/login">
                      <Redirect to="/admin" />
                    </Route>
                    <Route path="/admin">
                      <PageCacheProvider>
                        {(loaded) => (
                          <>{loaded && <AdminPage {...{ setAdminLogged }} />}</>
                        )}
                      </PageCacheProvider>
                    </Route>
                  </Switch>
                ) : (
                  <Switch>
                    <Route exact path="/admin/login">
                      <AdminLogin {...{ setAdminLogged }} />
                    </Route>
                    <Route path="/admin">
                      <Redirect to="/admin/login" />
                    </Route>
                  </Switch>
                )}
              </Route>
              <Route path="/">
                <Redirect to="/admin" />
              </Route>
            </Switch>
          </Router>
        </AlertProvider>
      </GoogleOAuthProvider>
    </Sentry.ErrorBoundary>
  );
};

export default App;
