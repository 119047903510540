import React, { useEffect } from "react";
import { getAlgoliaResults } from "@algolia/autocomplete-js";
import algoliasearch from "algoliasearch";
import AlgoliaAutocomplete from "./AlgoliaAutocomplete";
import UilTimes from "@iconscout/react-unicons/icons/uil-times";
import { getAdminFlags } from "utils/localStorageService";
import { epochToHumanReadable } from "utils/date";
import { admin_project as $ } from "strings";
import {stageOptions} from "utils/globalConsts";

const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_SEARCH_KEY
);

const AlgoliaGlobalSearch = ({ onClose = false }) => {
  const flags = getAdminFlags();
  useEffect(() => {
    if (onClose !== false) {
      const input = document.getElementsByClassName("aa-Input")[0];

      input.focus();
    }
  }, []);

  return (
    <div className="h-full w-full md:w-3/4 lg:w-1/2 xl:w-1/3 2xl:w-1/4">
      <div className="z-50 h-full w-full flex items-center relative">
        {onClose && (
          <div
            className="absolute text-kasmir right-0 top-0 z-10 h-11 w-11 flex items-center justify-center mt-1 pt-1 pr-px"
            onClick={(e) => {
              e.stopPropagation();
              onClose();
            }}
          >
            <UilTimes size="22" />
          </div>
        )}
        <AlgoliaAutocomplete
          classNames={{
            root: "globalsearch-aa-root",
          }}
          openOnFocus={false}
          getSources={({ query, setContext }) => [
            {
              sourceId: "talent",
              getItems() {
                return getAlgoliaResults({
                  searchClient,
                  queries: [
                    {
                      indexName: "talent_index",
                      query,
                      params: { hitsPerPage: 5 },
                    },
                  ],
                  transformResponse({ results, hits }) {
                    setContext({
                      nbTalentHits: results[0].nbHits,
                    });

                    return hits;
                  },
                });
              },
              templates: {
                item: ({ item, components }) => (
                  <a
                    href={`/admin/talent/${item.user_id}/overview`}
                    className="py-2 font-lato"
                  >
                    <div className="text-midnight text-base">
                      <components.Highlight attribute="user_name" hit={item} />
                    </div>
                    <div className="text-kasmir text-sm">
                      <components.Highlight attribute="user_email" hit={item} />
                    </div>
                    <div className="flex text-kasmir text-xs font-lato">
                    {item.created_at && `Created ${epochToHumanReadable(item.created_at)}`}
                    </div>
                  </a>
                ),
                header: ({ state }) => (
                  <div className="flex items-center justify-between mt-3 mb-2 border-b border-link-lighter pb-1 font-lato">
                    <h2 className="font-bold uppercase text-xs text-kasmir">
                      Talents
                    </h2>
                    {state.context.nbTalentHits > 5 && (
                      <a
                        href={`/admin/talent/search/${state.query}`}
                        className="text-xs text-kasmir rounded-sm hover:bg-link-water p-1"
                      >
                        {state.context.nbTalentHits - 5} additional results
                        found.
                      </a>
                    )}
                  </div>
                ),
              },
              getItemUrl({ item }) {
                return `/admin/talent/${item.user_id}/overview`;
              },
            },
            {
              sourceId: "project",
              getItems() {
                return getAlgoliaResults({
                  searchClient,
                  queries: [
                    {
                      indexName: "project_index",
                      query,
                      params: { hitsPerPage: 5 },
                    },
                  ],
                  transformResponse({ results, hits }) {
                    setContext({
                      nbProjectHits: results[0].nbHits,
                    });

                    return hits;
                  },
                });
              },
              templates: {
                item: ({ item, components }) => (
                  <a
                    href={`/admin/project/${item.objectID}/`}
                    className="py-2 font-lato"
                  >
                    <div className="text-midnight text-base">
                      <components.Highlight attribute="name" hit={item} />
                    </div>
                    <div className="flex items-center justify-between">
                      <div className="flex text-kasmir text-xs">
                        {item.timestamp && `Created ${epochToHumanReadable(item.timestamp)}`}
                      </div>
                      <div className="flex text-kasmir text-xs">
                        Stage: {stageOptions.find((o) => o.value === item.stage)?.label || 'Unknown'}
                      </div>
                    </div>
                  </a>
                ),
                header: ({ state }) => (
                  <div className="flex items-center justify-between mt-3 mb-2 border-b border-link-lighter pb-1 font-lato">
                    <h2 className="font-bold uppercase text-xs text-kasmir">
                      Projects
                    </h2>
                    {state.context.nbProjectHits > 5 && (
                      <a
                        href={`/admin/project/search/${state.query}`}
                        className="text-xs text-kasmir rounded-sm hover:bg-link-water p-1"
                      >
                        {state.context.nbProjectHits - 5} additional results
                        found.
                      </a>
                    )}
                  </div>
                ),
              },
              getItemUrl({ item }) {
                return `/admin/project/${item.objectID}/`;
              },
            },
            ({
              sourceId: "company",
              getItems() {
                return getAlgoliaResults({
                  searchClient,
                  queries: [
                    {
                      indexName: "company_index",
                      query,
                      params: { hitsPerPage: 5 },
                    },
                  ],
                  transformResponse({ results, hits }) {
                    setContext({
                      nbCompanyHits: results[0].nbHits,
                    });

                    return hits;
                  },
                });
              },
              templates: {
                item: ({ item, components }) => (
                  <a href={`/admin/company/${item.objectID}/`} className="py-2 font-lato">
                    <div className="text-midnight text-base">
                      <components.Highlight attribute="name" hit={item} />{" "}
                    </div>
                    <div className="flex text-kasmir text-xs">
                      {item.created_at && `Created ${epochToHumanReadable(item.created_at)}`}
                    </div>
                  </a>
                ),
                header: ({ state }) => (
                  <div className="flex items-center justify-between mt-3 mb-2 border-b border-link-lighter pb-1 font-lato">
                    <h2 className="font-bold uppercase text-xs text-kasmir">
                      Companies
                    </h2>
                    {state.context.nbCompanyHits > 5 && (
                      <a
                        href={`/admin/company/search/${state.query}`}
                        className="text-xs text-kasmir rounded-sm hover:bg-link-water p-1"
                      >
                        {state.context.nbCompanyHits - 5} additional results
                        found.
                      </a>
                    )}
                  </div>
                ),
              },
              getItemUrl({ item }) {
                return `/admin/company/${item.objectID}/`;
              },
            }),
            {
              sourceId: "contact",
              getItems() {
                return getAlgoliaResults({
                  searchClient,
                  queries: [
                    {
                      indexName: "contact_index",
                      query,
                      params: { hitsPerPage: 5 },
                    },
                  ],
                  transformResponse({ results, hits }) {
                    setContext({
                      nbContactHits: results[0].nbHits,
                    });

                    return hits;
                  },
                });
              },
              templates: {
                item: ({ item, components }) => (
                  <a
                    href={`/admin/contacts/${item.user_id}/contact_info`}
                    className="py-2 font-lato"
                  >
                    <div className="text-midnight text-base">
                      <components.Highlight attribute="name" hit={item} />
                    </div>
                    <div className="text-kasmir text-sm">
                      <components.Highlight attribute="email" hit={item} />
                    </div>
                    <div className="flex text-kasmir text-xs">
                      {item.created_at && `Created ${epochToHumanReadable(item.created_at)}`}
                    </div>
                  </a>
                ),
                header: ({ state }) => (
                  <div className="flex items-center justify-between mt-3 mb-2 border-b border-link-lighter pb-1 font-lato">
                    <h2 className="font-bold uppercase text-xs text-kasmir">
                      Contacts
                    </h2>
                    {state.context.nbContactHits > 5 && (
                      <a
                        href={`/admin/contacts/search/${state.query}`}
                        className="text-xs text-kasmir rounded-sm hover:bg-link-water p-1"
                      >
                        {state.context.nbContactHits - 5} additional results
                        found.
                      </a>
                    )}
                  </div>
                ),
              },
              getItemUrl({ item }) {
                return `/admin/contacts/${item.user_id}/contact_info`;
              },
            },
          ]}
        />
      </div>
    </div>
  );
};

export default AlgoliaGlobalSearch;
