import React from "react";
import RatingInput from "./RatingInput";


const Qualification = ({ qualification, rating, onRatingChange }) => {
  const { name, id } = qualification;

  return (
    <div className="flex w-full p-2 bg-lightest-grey text-sm font-medium rounded-xl">
      <div className="ml-0 mr-auto">{name}</div>
      <div className="mr-0 ml-auto">
        <RatingInput
          value={rating}
          onChange={(v) => onRatingChange(id, v)}
        />
      </div>
    </div>
  )
};

export default Qualification;
