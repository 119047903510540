import React from "react";

const Pill = ({ value }) => {
  return (
    <div className="bg-dark-navy rounded-xl text-white text-mini px-2">
      {value}
    </div>
  )
}

export default Pill;