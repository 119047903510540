import React from "react";
import clsx from "clsx";

const PerformanceMarketingIcon = ({
  isSelected
}) => {
  return (
    <div className={clsx('rounded-lg p-2', isSelected ? 'bg-electric-indigo' : 'bg-lightest-grey')}>
      <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_951_1702)">
          <path d="M26.4591 0.557781C26.3684 0.238316 26.0872 0 25.7385 0H22.6916C22.2706 0 21.9299 0.340691 21.9299 0.761719C21.9299 1.18275 22.2706 1.52344 22.6916 1.52344H24.4731L20.8355 5.63052C15.9312 11.1783 8.71392 14.1106 1.32734 13.4687C0.921948 13.4263 0.539566 13.7424 0.503156 14.1619C0.466695 14.5807 0.776917 14.9497 1.19642 14.9861C1.90903 15.0479 2.51034 15.0783 3.22076 15.0783C10.3388 15.0783 17.227 12.0113 21.9766 6.63914L24.9768 3.25305V3.80859C24.9768 4.22962 25.3175 4.57031 25.7385 4.57031C26.1595 4.57031 26.5002 4.22962 26.5002 3.80859V1.51338L26.4591 0.557781Z" fill={isSelected ? '#fff ': '#4C5167'}/>
          <path d="M25.7383 24.4766H24.9258V7.35115C24.9258 7.03128 24.7257 6.74563 24.4252 6.63554C24.1254 6.52768 23.7884 6.61543 23.5817 6.86019L23.1138 7.41584C22.3402 8.33376 21.4973 9.20634 20.6076 10.0075C20.447 10.1518 20.3555 10.3578 20.3555 10.5735V24.4766H18.832V12.9502C18.832 12.6691 18.6765 12.4102 18.4289 12.2778C18.1812 12.1469 17.8784 12.1603 17.6456 12.318C16.7001 12.9532 15.7033 13.5335 14.6827 14.0437C14.4246 14.1724 14.2617 14.4365 14.2617 14.7251V24.4766H12.7383V16.0239C12.7383 15.7762 12.6178 15.5441 12.4155 15.4012C12.2139 15.2592 11.9535 15.2249 11.7207 15.3068C10.7544 15.6512 9.75762 15.9435 8.75784 16.1741C8.41268 16.2545 8.16797 16.5617 8.16797 16.9165V24.4766H6.64453V17.4417C6.64453 17.2245 6.55155 17.0177 6.39012 16.8734C6.22721 16.7291 6.00626 16.6554 5.79654 16.6852C4.8191 16.7968 3.82307 16.854 2.83523 16.8555C2.41496 16.8563 2.07427 17.197 2.07427 17.6172V24.4766H1.26172C0.840691 24.4766 0.5 24.8173 0.5 25.2383C0.5 25.6594 0.840691 26.0001 1.26172 26.0001H25.7383C26.1593 26.0001 26.5 25.6594 26.5 25.2383C26.5 24.8173 26.1593 24.4766 25.7383 24.4766Z" fill={isSelected ? '#fff ': '#4C5167'}/>
        </g>
        <defs>
          <clipPath id="clip0_951_1702">
            <rect width="26" height="26" fill={isSelected ? '#3049c5': '#fff'} transform="translate(0.5)"/>
          </clipPath>
        </defs>
      </svg>
    </div>
  )
};

export default PerformanceMarketingIcon;
