import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {admin_contact as $} from "strings";
import {
  deleteUserLink,
  getTalentAgreements,
  patchContactOverviewV2
} from "utils/adminApi";
import {usePageCache} from "contexts/pageCacheContext";
import LoadPlaceholder from "components/LoadPlaceholder";
import {formatPhone, withHttp} from "utils/str";
import {B2DB} from "components/Typography";
import AddContactInfo from "./AddContactInfo";
import {useAlert} from "react-alert";
import AlertError from "components/AlertError";
import UilEnvelope from "@iconscout/react-unicons/icons/uil-envelope";
import UilPhone from "@iconscout/react-unicons/icons/uil-phone";
import UilLink from "@iconscout/react-unicons/icons/uil-link";
import UilTrashAlt from "@iconscout/react-unicons/icons/uil-trash-alt";
import UilCopy from "@iconscout/react-unicons/icons/uil-copy";
import UilCheck from "@iconscout/react-unicons/icons/uil-check";
import DeletePersonalEmailForm from "./DeletePersonalEmail";
import {uniq} from 'lodash';
import {v4 as uuidv4} from "uuid";


const emailTypes = [
  { label: 'Login Email Address', value: "login" },
  { label: 'Finance Email Address', value: "finance" },
  { label: 'Clockify Email Address', value: "clockify" },
  { label: 'Talent Email Address', value: "talent" },
  { label: 'Client Email Address', value: "client" },
  { label: 'Investor Email Address', value: "investor" },
  { label: 'HubSpot Email Address', value: "primary" },
  { label: 'Additional Email Address', value: "additional" }
];

const linkTypes = [
  { label: 'LinkedIn', value: "linkedin" },
  { label: 'Personal Website', value: "website" },
]

const boolOptions = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' }
];

const placeholderData = {
  login_email: '',
  finance_email: '',
  clockify_email: '',
  phone_number: '',
  full_address: '',
  first_name: '',
  last_name: '',
  pronouns: '',
  preferred_name: ''
}

const ContactPreferences = ({
  data,
  setData,
  contacts,
  setContacts,
  contactLinks,
  setContactLinks,
  talentRole,
  hubspotInputsDisabled,
  onDisabledClick
}) => {
  const { id } = useParams();
  const alert = useAlert();
  const { set } = usePageCache();
  const [hasClip, setHasClip] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isTalent, setIsTalent] = useState(false);
  const [toDelete, setToDelete] = useState();
  const [optIn, setOptIn] = useState(false);
  const [additional_email_address, setAdditionalEmailAddresses] = useState([]);
  const [modal, setModal] = useState(null);

  useEffect(() => {
    set("url_contact", `/admin/contacts/${id}/contact_info`);
  }, [set]);

  let {
    email: login_email,
    finance_email,
    primary_talent_email,
    primary_client_email,
    primary_investor_email,
    clockify_email,
    first_name,
    last_name,
    hs_primary_email,
    hs_additional_emails
  } = data || placeholderData;

  useEffect(() => {
    if (!talentRole || talentRole === false) return;
    setIsTalent(true);
    getAgreements();
  }, [talentRole]);

  useEffect(() => {
    if (hs_additional_emails) {
      setAdditionalEmailAddresses(hs_additional_emails.split(';'));
    }
  }, [hs_additional_emails]);

  const getAgreements = async () => {
    const response = await getTalentAgreements(talentRole.id);
    setOptIn(response?.textmessage || false);
  }

  const NoContactData = ({ type }) => (
    <div className="flex items-center">
      <div className="py-2 text-sm font-normal text-midnight">{`No ${type} found for ${first_name} ${last_name}. To add one, click Add Contact Info above.`}</div>
    </div>
  );

  const copyToClipboard = (data, e) => {
    navigator.clipboard.writeText(data);
    e ? setHasClip(e.currentTarget?.id) : setHasClip(data);
    setTimeout(() => {
      setHasClip(false);
    }, 1000);
  }

  const onAddPhoneSuccess = async (newPhone) => {
    setData({ ...data, phone_number: newPhone });
  }

  const onAddLinkSuccess = async (link) => {
    setContactLinks([...contactLinks, link]);
    setData({...data, attached_links: data.attached_links ? [...data.attached_links, {url: link.url, name: link.name} ] : [ {url: link.url, name: link.name}] });
  }

  const onUpdateLinkSuccess = async (link) => {
    const newArr = contactLinks.filter(l => l.id !== link.id);
    newArr.push(link);
    setContactLinks([...newArr]);

    const newAttachedLinks = data.attached_links.filter(l => l.name !== link.name);
    newAttachedLinks.push({name: 'linkedin', url: link.url});
    setData({...data, attached_links: [...newAttachedLinks] });
  }

  const onAddEmailSuccess = async (type, email, contact) => {
    if (type === 'login') {
      setData({...data, email: email });
    } else if (type === 'finance') {
      setData({...data, finance_email: email });
    }
  }

  const showDeleteConfirmationModal = (email) => {
    setToDelete(email);
    setModal('deletePersonalEmail');
  }

  const onDeletePersonalEmailAddress = async ({ email_type }) => {
    const origVal = data[email_type];
    setData({ ...data, [email_type]: null })

    const toSend = {};

    if (email_type === 'login_email') {
      toSend.email = null;
    } else if (email_type === 'finance_email') {
      toSend.finance_email = null;
    }

    try {
      await patchContactOverviewV2(id, toSend);
      setToDelete();
      setModal();
    } catch (e) {
      setData({ ...data, [email_type]: origVal });
      console.error(e);
      alert.error(<AlertError error={e} onRetry={() => onDeletePersonalEmailAddress} />);
    };
  }

  const onDeleteLink = async (link) => {
    try {
      await deleteUserLink(link.id);
      setContactLinks([...contactLinks.filter(l => l.id !== link.id)]);
      setData({...data, attached_links: [...data.attached_links.filter(l => l.url !== link.url)]});
    } catch (e) {
      console.error(e);
      alert.error(<AlertError error={e} />);
    }
  }

  const EmailAddress = ({email, type}) => (
    <>{email && (<div className="flex items-center flex-1 font-normal py-px">
      <div id={`clip${type}`} className="flex items-center w-1/2" onClick={(e)=> copyToClipboard(email, e)}>
        <div title={email} className='truncate'>{email}</div>
        {hasClip === `clip${type}` ?
          <span className="ml-1"><UilCheck size="16" /></span> :
          <span className="ml-1"><UilCopy size="16" /></span>
        }
      </div>
      <div className="w-1/2">{emailTypes.find(e => e.value === type)?.label}</div>
      <div className="w-8"><slot/></div>
    </div>)}</>
  );

  const EmailAddresses = () => (
    <div className="bg-white flex-1 flex flex-col w-3/5">
      <div className="flex items-center px-2 pt-4 py-2">
        <B2DB className="flex flex-1 items-center">
          <UilEnvelope className="mr-2 text-link" size="18" />{$.email_addresses_title}
        </B2DB>
      </div>
      <div className="px-2 py-2">
        <div className="flex flex-col text-sm text-kasmir font-bold">
          <div className="flex items-center flex-1 mb-1">
            <div className="w-1/2">{$.email_address_label}</div>
            <div className="w-1/2">{$.email_type_label}</div>
            <div className="w-8"></div>
          </div>
          <EmailAddress email={login_email} type='login' />
          <EmailAddress email={hs_primary_email} type='primary' />
          <EmailAddress email={primary_talent_email} type='talent' />
          <EmailAddress email={primary_client_email} type='client' />
          <EmailAddress email={primary_investor_email} type='investor' />
          <EmailAddress email={clockify_email} type='clockify' />
          <EmailAddress email={finance_email} type='finance'>
            <button
              type="button"
              className="group focus:outline-none h-5 cursor-pointer"
              onClick={() => {
                if (hubspotInputsDisabled) {
                  onDisabledClick();
                  return;
                }
                showDeleteConfirmationModal({
                  email: finance_email,
                  email_type: 'finance_email'
                })
              }}>
                <span
                  className="group-focus:ring focus:outline-none text-sm font-normal rounded px-1 h-5 inline-flex items-center justify-center text-link bg-white"
                  tabIndex="-1"
                >
                  <UilTrashAlt size="20" className="mr-1"/>
                </span>
            </button>
          </EmailAddress>
          {additional_email_address?.length ? uniq(additional_email_address).map((email, i) => (
            <div className="flex items-center flex-1 font-normal" key={uuidv4()}>
              <div id={`clipAdditionalEmail--${i}`} className="flex items-center w-1/2" onClick={(e)=> copyToClipboard(email, e)}>
                <div title={email} className='truncate'>{email}</div>
                {hasClip === `clipAdditionalEmail--${i}` ?
                  <span className="ml-1"><UilCheck size="16" /></span> :
                  <span className="ml-1"><UilCopy size="16" /></span>
                }
              </div>
              <div className="w-1/2">{emailTypes.find(e => e.value === 'additional')?.label}</div>
              <div className="w-8"></div>
            </div>
          )) : <></>}
        </div>
      </div>
    </div>
)

const PhoneNumber = () => (
  <div className="bg-white flex-1 flex flex-col w-3/5">
    <div className="flex items-center px-2 pt-4 py-2">
      <B2DB className="flex flex-1 items-center">
        <UilPhone className="mr-2 text-link" size="18"/>{$.phone_numbers_title}
      </B2DB>
    </div>
    <div className="px-2 py-2">
      <div className="flex flex-col text-sm text-kasmir font-bold">
        {data?.phone_number ? <>
          <div className="flex items-center flex-1 mb-1">
            <div className="w-1/2">{$.phone_number_label}</div>
            <div className="w-1/2">{$.opt_in_label}</div>
            <div className="w-8"></div>
          </div>
          <div className="flex items-center flex-1 font-normal">
              <div id="clipPhone" className="w-1/2 flex items-center" onClick={(e)=> copyToClipboard(data?.phone_number, e)}>
                <>{formatPhone(data?.phone_number)}</>
                {hasClip === 'clipPhone' ?
                  <span className="ml-1"><UilCheck size="16" /></span> :
                  <span className="ml-1"><UilCopy size="16" /></span>
                }
              </div>
              <div className="w-1/2">{boolOptions.find(o => o.value === optIn).label || $.unknown_value}</div>
              <div className="w-8"> </div>
            </div></> : <NoContactData type="phone numbers" />}
        </div>
      </div>
    </div>
  )

  const ContactLinks = () => (
    <div className="bg-white flex-1 flex flex-col w-3/5">
      <div className="flex items-center px-2 pt-4 py-2">
        <B2DB className="flex flex-1 items-center">
          <UilLink className="mr-2 text-link" size="18" />{$.links_title}
        </B2DB>
      </div>
      <div className="px-2 py-2">
        <div className="flex flex-col text-sm text-kasmir font-bold">
          {contactLinks.length ? <>
            <div className="flex items-center flex-1 mb-1">
              <div className="w-1/2">{$.link_label}</div>
              <div className="w-1/2">{$.link_type_label}</div>
              <div className="w-8"></div>
            </div>
            {contactLinks.map(link => (
              <div className="flex items-center flex-1 font-normal" key={link.id}>
                <div className="w-1/2 flex items-center">
                  <Link
                    className="text-sm font-normal text-midnight"
                    to={{pathname: withHttp(link.url) }}
                    target="_blank"
                  >
                    {link.url}
                  </Link>
                  {hasClip === link.url ?
                    <span className="ml-1"><UilCheck size="16" /></span> :
                    <span className="ml-1" onClick={()=> copyToClipboard(link.url)}><UilCopy size="16" /></span>
                  }
                </div>
                <div className="w-1/2">{linkTypes.find(l => l.value === link.name.toLowerCase())?.label || link.name}</div>
                <div className="w-8">
                  <button
                    type="button"
                    className="group focus:outline-none h-5 cursor-pointer"
                    onClick={() => {
                      if (hubspotInputsDisabled) {
                        onDisabledClick();
                        return;
                      }
                      onDeleteLink(link)
                  }}>
                    <span
                      className="group-focus:ring focus:outline-none text-sm font-normal rounded px-1 h-5 inline-flex items-center justify-center text-link bg-white"
                      tabIndex="-1"
                    >
                      <UilTrashAlt size="20" className="mr-1" />
                    </span>
                  </button>
                </div>
              </div>
            ))}</> : <NoContactData type="links" />}
        </div>
      </div>
    </div>
  )

  return (
    <div className="contact_preferences pb-10">
      {loading || !data ?
        <div className="flex-1 px-6 pt-6">
          <LoadPlaceholder className="w-60 h-4 my-4" />
          <LoadPlaceholder className="w-60 h-4 mb-4" />
          <LoadPlaceholder className="w-40 h-4 mb-2" />
        </div> : <>
          <AddContactInfo
            data={data}
            contactLinks={contactLinks}
            onAddEmailSuccess={onAddEmailSuccess}
            onAddPhoneSuccess={onAddPhoneSuccess}
            onAddLinkSuccess={onAddLinkSuccess}
            onUpdateLinkSuccess={onUpdateLinkSuccess}
            isTalent={isTalent}
            disabled={hubspotInputsDisabled}
            onDisabledClick={onDisabledClick}
          />
          <div className="flex flex-col gap-4">
            <EmailAddresses />
            <PhoneNumber />
            <ContactLinks />
          </div>
        </>
      }
      {modal === "deletePersonalEmail" &&
        <DeletePersonalEmailForm
          toDelete={toDelete}
          onDelete={() =>onDeletePersonalEmailAddress(toDelete)}
          onClose={setModal}
        />
      }
    </div>
  );
};

export default ContactPreferences;
