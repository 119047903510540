import React from "react";
import clsx from "clsx";

const OfflineIcon = ({
  isSelected
}) => {
  return (
    <div className={clsx('rounded-lg p-2', isSelected ? 'bg-electric-indigo' : 'bg-lightest-grey')}>
      <svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_3007_8523)">
          <path d="M20.9997 6.50024H4.99994C2.23991 6.50356 0.00331246 8.74016 0 11.5002V21.5001C0.00331246 24.2601 2.23991 26.4967 4.99994 26.5H20.9997C23.7598 26.4967 25.9964 24.2601 25.9997 21.5001V11.5002C25.9964 8.74022 23.7598 6.50356 20.9997 6.50024ZM8.99989 22.5001C5.68624 22.5001 2.99996 19.8138 2.99996 16.5001C2.99996 13.1865 5.68624 10.5002 8.99989 10.5002C12.3135 10.5002 14.9998 13.1865 14.9998 16.5001C14.9965 19.8125 12.3122 22.4968 8.99989 22.5001ZM21.9997 21.5001H17.9998C17.4475 21.5001 16.9998 21.0524 16.9998 20.5001C16.9998 19.9478 17.4475 19.5001 17.9998 19.5001H21.9997C22.552 19.5001 22.9997 19.9478 22.9997 20.5001C22.9997 21.0524 22.552 21.5001 21.9997 21.5001ZM21.9997 17.5001H17.9998C17.4475 17.5001 16.9998 17.0524 16.9998 16.5001C16.9998 15.9478 17.4475 15.5001 17.9998 15.5001H21.9997C22.552 15.5001 22.9997 15.9478 22.9997 16.5001C22.9997 17.0524 22.552 17.5001 21.9997 17.5001ZM21.9997 13.5002H17.9998C17.4475 13.5002 16.9998 13.0525 16.9998 12.5002C16.9998 11.9479 17.4475 11.5002 17.9998 11.5002H21.9997C22.552 11.5002 22.9997 11.9479 22.9997 12.5002C22.9997 13.0525 22.552 13.5002 21.9997 13.5002Z" fill={isSelected ? '#fff ': '#4C5167'}/>
          <path d="M8.99995 20.5001C11.2091 20.5001 12.9999 18.7092 12.9999 16.5001C12.9999 14.291 11.2091 12.5002 8.99995 12.5002C6.79084 12.5002 5 14.291 5 16.5001C5 18.7092 6.79084 20.5001 8.99995 20.5001Z" fill={isSelected ? '#fff ': '#4C5167'}/>
          <path d="M5.00018 8.5002C4.44787 8.50032 4.00013 8.0527 4 7.5004C3.99994 7.07634 4.26731 6.69834 4.66718 6.55722L21.667 0.557291C22.1878 0.373356 22.759 0.646478 22.943 1.16728C23.1269 1.68809 22.8538 2.25933 22.333 2.44327L5.33317 8.4432C5.22617 8.48095 5.11361 8.50026 5.00018 8.5002Z" fill={isSelected ? '#fff ': '#4C5167'}/>
        </g>
        <defs>
          <clipPath id="clip0_3007_8523">
            <rect width="26" height="26" fill={isSelected ? '#3049c5': '#fff'} transform="translate(0 0.5)"/>
          </clipPath>
        </defs>
      </svg>
    </div>
  )
};

export default OfflineIcon;
