import React from "react";
import clsx from "clsx";

const LeadershipIcon = ({
  isSelected
}) => {
  return (
    <div className={clsx('rounded-lg p-2', isSelected ? 'bg-electric-indigo' : 'bg-lightest-grey')}>
      <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1084_5657)">
          <path d="M21.6667 17.3333C20.4717 17.3333 19.5 16.3616 19.5 15.1667C19.5 13.9717 20.4717 13 21.6667 13C22.8616 13 23.8333 13.9717 23.8333 15.1667C23.8333 16.3616 22.8616 17.3333 21.6667 17.3333Z" fill={isSelected ? '#fff ': '#4C5167'} />
          <path d="M4.33317 17.3333C3.13825 17.3333 2.1665 16.3616 2.1665 15.1667C2.1665 13.9717 3.13825 13 4.33317 13C5.52809 13 6.49984 13.9717 6.49984 15.1667C6.49984 16.3616 5.52809 17.3333 4.33317 17.3333Z" fill={isSelected ? '#fff ': '#4C5167'} />
          <path d="M13 17.875C11.2082 17.875 9.75 16.4168 9.75 14.625C9.75 12.8332 11.2082 11.375 13 11.375C14.7918 11.375 16.25 12.8332 16.25 14.625C16.25 16.4168 14.7918 17.875 13 17.875Z" fill={isSelected ? '#fff ': '#4C5167'} />
          <path d="M18.1457 24.9167H7.854C7.4055 24.9167 7.0415 24.5527 7.0415 24.1042V22.4792C7.0415 20.8358 8.37725 19.5 10.0207 19.5H15.979C17.6224 19.5 18.9582 20.8358 18.9582 22.4792V24.1042C18.9582 24.5527 18.5942 24.9167 18.1457 24.9167Z" fill={isSelected ? '#fff ': '#4C5167'} />
          <path d="M23.0207 18.4167H20.3124C19.9657 18.4167 19.6407 18.4708 19.3374 18.5792C20.6125 19.6907 21.1249 21.0741 21.1249 22.75H25.1874C25.6316 22.75 25.9999 22.3817 25.9999 21.9375V21.3958C25.9999 19.7492 24.6674 18.4167 23.0207 18.4167Z" fill={isSelected ? '#fff ': '#4C5167'} />
          <path d="M5.6875 18.4167H2.97917C1.3325 18.4167 0 19.7492 0 21.3958V21.9375C0 22.3817 0.368333 22.75 0.8125 22.75H4.875C4.875 21.0741 5.38742 19.6907 6.6625 18.5792C6.35917 18.4708 6.03417 18.4167 5.6875 18.4167Z" fill={isSelected ? '#fff ': '#4C5167'} />
          <path d="M16.7561 9.75H9.24423C8.85315 9.75 8.5184 9.47158 8.44582 9.08808L7.59757 4.57383C7.47732 3.93358 8.12407 3.41575 8.72532 3.68117L10.5453 4.48933L12.2981 1.48742C12.5906 0.988 13.4096 0.988 13.7021 1.48742L15.455 4.48933L17.275 3.68117C17.8719 3.41683 18.523 3.93142 18.4038 4.57383L17.5556 9.08808C17.483 9.47158 17.1471 9.75 16.7561 9.75Z" fill={isSelected ? '#fff ': '#4C5167'} />
        </g>
        <defs>
          <clipPath id="clip0_1084_5657">
            <rect width="26" height="26" fill="white"/>
          </clipPath>
        </defs>
      </svg>
    </div>
  )
};

export default LeadershipIcon;
