import React from "react";
import $ from "strings/onboard";
import {
  Label,
  Sublabel,
  Input,
  Checkbox
} from "components/Form";
import { Error } from "../components/index";
import DatePicker from "components/DatePicker";

const WORKING_TIMEZONES_OPTIONS = [
  {label: $.est_option, value: 'eastern'},
  {label: $.cst_option, value: 'central'},
  {label: $.mst_option, value: 'mountain'},
  {label: $.pst_option, value: 'pacific'}
]

const Availability = ({
  setFieldValue,
  values,
  touched,
  onBlur,
  errors,
  setFieldError
}) => {
  const onChange = (e) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
    if (errors[name]) {
      setFieldError(name, null)
    }
  };

  const onCheckboxClick = (fieldName, option, value) => {
    const currentValues = values[fieldName];
    let currentValuesArr = currentValues?.length ? currentValues.split('|') : [];
    if (value === true && currentValuesArr.indexOf(option) === -1) {
      currentValuesArr.push(option);
      if (errors[fieldName]) {
        setFieldError(fieldName, null);
      }
    } else if (value === false && currentValuesArr.indexOf(option) > -1) {
      currentValuesArr.splice(currentValuesArr.indexOf(option), 1)
    }
    const newValues = currentValuesArr.join('|')
    setFieldValue(fieldName, newValues);
  }

  return (
    <div className="w-full h-full p-4">
      <div className="py-1 mb-auto">
        <div className="text-darkest-navy text-2xl sm:text-3xl font-bold py-2">{$.availability_title}</div>
        <div className="text-midnight text-base sm:text-lg font-normal">{$.availability_subtitle}</div>
      </div>
      <div className="flex flex-col gap-2 py-4">
        <div className="my-1">
          <Label className="w-full sm:w-1/2 font-normal text-darkest-navy text-sm sm:text-base">{$.availability_label}</Label>
          <Sublabel className="w-full sm:w-1/2">{$.availability_sublabel}</Sublabel>
          <div className="w-full sm:w-1/2">
            <Input
              type="number"
              name="hoursAvailable"
              maxLength="100"
              placeholder={$.amount_placeholder}
              onChange={onChange}
              onBlur={onBlur}
              value={values.hoursAvailable}
              error={errors.hoursAvailable}
              fontSize={'text-sm sm:text-base'}
              children={<span className="absolute right-3 top-2 bottom-2">{$.hrs_per_week_label}</span>}
            />
            {(!touched.hoursAvailable && errors.hoursAvailable) ? <Error msg={errors.hoursAvailable} /> : <div style={{height: '22px'}} />}
          </div>
        </div>
        <div className="my-1">
          <Label className="w-full sm:w-1/2 font-normal text-darkest-navy text-sm sm:text-base">{$.availability_expiration_date_label}</Label>
          <Sublabel className="w-full sm:w-1/2">{$.availability_expiration_date_sublabel}</Sublabel>
            <DatePicker
              isV2
              className="w-full sm:w-1/2"
              placeholderText={$.availability_expiration_date_placeholder}
              value={
                values.hoursExpirationDate
                ? new Date(values.hoursExpirationDate)
                : null
              }
              format="MMMM dd, yyyy"
              monthly={false}
              isClearable={false}
              onChange={(v) => setFieldValue('hoursExpirationDate', v)}
            />
            {(!touched.hoursExpirationDate && errors.hoursExpirationDate) ? <Error msg={errors.hoursExpirationDate} /> : <div style={{height: '22px'}} />}
          </div>
        <div className="my-1">
          <Label className="font-normal text-darkest-navy text-sm sm:text-base">{$.working_timezones_label}</Label>
          <Sublabel>{$.select_all_sublabel}</Sublabel>
          <div className="my-px">
            {WORKING_TIMEZONES_OPTIONS.map((option) => 
              <Checkbox
                outlined
                key={option.value}
                value={values['workingTimezones']?.includes(option.value) ? true : false}
                onChange={(v) => onCheckboxClick('workingTimezones', option.value, v)}
              >
                <div className="ml-1 my-1 font-sm text-darkest-navy font-lato">{option.label}</div>
              </Checkbox>
            )}
            {(!touched.workingTimezones && errors.workingTimezones) ? <Error msg={errors.workingTimezones} /> : <div style={{height: '22px'}} />}
          </div>
        </div>
      </div>
    </div>
  )
};

export default Availability;
