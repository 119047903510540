
import React from "react";

const CompletedCheckIcon = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1596_1377)">
        <circle cx="9" cy="9" r="9" fill="#00A92F"/>
        <path d="M5.40039 9.90005L7.41639 11.7L12.6004 6.30005" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      </g>
      <defs>
        <clipPath id="clip0_1596_1377">
          <rect width="18" height="18" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
};

export default CompletedCheckIcon;
