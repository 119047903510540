
import React from "react";

const CalendarIcon = ({
  isSelected
}) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 0C8.44184 0 8.8 0.358176 8.8 0.8H11.2C11.2 0.358176 11.5582 0 12 0C12.4418 0 12.8 0.358176 12.8 0.8C14.5673 0.8 16 2.23269 16 4V4.00326C15.9737 4.0011 15.947 4 15.92 4H0.0799999C0.0530319 4 0.026352 4.0011 0 4.00326V4C0 2.23269 1.43269 0.8 3.2 0.8C3.2 0.358176 3.55818 0 4 0C4.44182 0 4.8 0.358176 4.8 0.8H7.2C7.2 0.358176 7.55816 0 8 0Z" fill="#0E1539"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M0 5.59686V12.8001C0 14.5674 1.43269 16.0001 3.2 16.0001H12.8C14.5673 16.0001 16 14.5674 16 12.8001V5.59686C15.9737 5.59902 15.947 5.60013 15.92 5.60013H0.0799999C0.0530319 5.60013 0.026352 5.59902 0 5.59686ZM5.6 8.80013C5.6 9.24197 5.24182 9.60013 4.8 9.60013C4.35818 9.60013 4 9.24197 4 8.80013C4 8.35829 4.35818 8.00013 4.8 8.00013C5.24182 8.00013 5.6 8.35829 5.6 8.80013ZM4.8 12.8001C5.24182 12.8001 5.6 12.442 5.6 12.0001C5.6 11.5583 5.24182 11.2001 4.8 11.2001C4.35818 11.2001 4 11.5583 4 12.0001C4 12.442 4.35818 12.8001 4.8 12.8001ZM12 8.80013C12 9.24197 11.6418 9.60013 11.2 9.60013C10.7582 9.60013 10.4 9.24197 10.4 8.80013C10.4 8.35829 10.7582 8.00013 11.2 8.00013C11.6418 8.00013 12 8.35829 12 8.80013ZM8 9.60013C8.44184 9.60013 8.8 9.24197 8.8 8.80013C8.8 8.35829 8.44184 8.00013 8 8.00013C7.55816 8.00013 7.2 8.35829 7.2 8.80013C7.2 9.24197 7.55816 9.60013 8 9.60013ZM8.8 12.0001C8.8 12.442 8.44184 12.8001 8 12.8001C7.55816 12.8001 7.2 12.442 7.2 12.0001C7.2 11.5583 7.55816 11.2001 8 11.2001C8.44184 11.2001 8.8 11.5583 8.8 12.0001ZM11.2 12.8001C11.6418 12.8001 12 12.442 12 12.0001C12 11.5583 11.6418 11.2001 11.2 11.2001C10.7582 11.2001 10.4 11.5583 10.4 12.0001C10.4 12.442 10.7582 12.8001 11.2 12.8001Z" fill="#0E1539"/>
    </svg>
  )
};

export default CalendarIcon;
