import React from "react";
import clsx from "clsx";

const EcommerceIcon = ({
  isSelected
}) => {
  return (
    <div className={clsx('rounded-lg p-2', isSelected ? 'bg-electric-indigo' : 'bg-lightest-grey')}>
      <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1895_8730)">
          <path d="M1.16667 1.16669C0.522334 1.16669 0 1.68902 0 2.33335C0 2.97769 0.522334 3.50002 1.16667 3.50002H3.24251C3.41238 3.50002 3.57793 3.55347 3.71573 3.65279C3.85352 3.75212 3.95658 3.89228 4.01028 4.05343L7.85677 15.5951C8.02759 16.1075 8.0493 16.6593 7.91829 17.1833L7.60156 18.4525C7.13683 20.3114 8.58385 22.1667 10.5 22.1667H24.5C25.1443 22.1667 25.6667 21.6444 25.6667 21C25.6667 20.3557 25.1443 19.8334 24.5 19.8334H10.5C10.0457 19.8334 9.75408 19.4606 9.86426 19.0199L10.1031 18.0659C10.1436 17.9042 10.2369 17.7608 10.3683 17.6582C10.4996 17.5557 10.6615 17.5 10.8281 17.5H23.3333C23.8357 17.5003 24.2818 17.179 24.4408 16.7025L27.5511 7.36916C27.8031 6.61341 27.2403 5.83299 26.4437 5.83335H7.64714C7.47727 5.83335 7.31171 5.77991 7.17391 5.68059C7.03611 5.58126 6.93305 5.4411 6.87934 5.27996L5.77409 1.96421C5.61511 1.4877 5.169 1.16642 4.66667 1.16669H1.16667ZM9.33333 23.3334C8.04467 23.3334 7 24.378 7 25.6667C7 26.9554 8.04467 28 9.33333 28C10.622 28 11.6667 26.9554 11.6667 25.6667C11.6667 24.378 10.622 23.3334 9.33333 23.3334ZM23.3333 23.3334C22.0447 23.3334 21 24.378 21 25.6667C21 26.9554 22.0447 28 23.3333 28C24.622 28 25.6667 26.9554 25.6667 25.6667C25.6667 24.378 24.622 23.3334 23.3333 23.3334Z" fill={isSelected ? '#fff ': '#4C5167'} />
        </g>
        <defs>
          <clipPath id="clip0_1895_8730">
            <rect width="28" height="28" fill={isSelected ? '#3049c5': '#fff'} />
          </clipPath>
        </defs>
      </svg>
    </div>
  )
};

export default EcommerceIcon;
