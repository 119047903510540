
import React from "react";

const RatingHelper = ({ label }) => {
  return (
    <div className="flex text-mini text-kasmir">
      <div className="mr-auto ml-1">
        {label} name
      </div>
      <div className="ml-auto mr-2 flex gap-2">
        <span>Beginner</span>
        <span>Intermediate</span>
        <span>Expert</span>
      </div>
    </div>
  )
};

export default RatingHelper;