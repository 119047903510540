import { admin_project_list as project } from "strings";

export const stageOptions = [
  { label: project.active_option, value: "active" },
  { label: project.pitching_option, value: "pitching" },
  { label: project.shadow_pitched_option, value: "shadow_pitched" },
  { label: project.matching_no_support_option, value: "matching_no_support" },
  { label: project.matching_support_option, value: "matching_support" },
  { label: project.sourcing_option, value: "sourcing" },
  { label: project.submitted_option, value: "submitted" },
  { label: project.contracting_option, value: "contracting" },
  { label: project.pipelines_option, value: "pipelines" },
  { label: project.passive_option, value: "passive" },
  { label: project.filled_option, value: "filled" },
  { label: project.lost_option, value: "lost" },
  { label: project.completed_option, value: "completed" },
];
