import React from "react";

const BrandMarketingIcon = ({
  isSelected
}) => {
  return (
    <svg width="46" height="47" viewBox="0 0 46 47" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y="0.5" width="46" height="46" rx="11.3332" fill={isSelected ? '#3049c5': '#fff'} />
      <g clipPath="url(#clip0_2189_9962)">
        <path d="M16 19.8307H12C9.2432 19.8307 7 22.0739 7 24.8307C7 27.5875 9.2432 29.8307 12 29.8307H16C16.5527 29.8307 17 29.3829 17 28.8307V20.8307C17 20.2785 16.5527 19.8307 16 19.8307Z" fill={isSelected ? '#fff ': '#4C5167'} />
        <path d="M38.9512 13.1744C38.833 12.8126 38.5205 12.5484 38.1436 12.4937L34.5186 11.9668L32.8965 8.68117C32.5606 7.99867 31.4395 7.99867 31.1035 8.68127L29.4814 11.9669L25.8564 12.4938C25.4794 12.5485 25.1669 12.8126 25.0488 13.1745C24.9316 13.5368 25.0293 13.9338 25.3017 14.1999L27.9257 16.757L27.3066 20.3683C27.2421 20.7433 27.3964 21.1227 27.7041 21.3463C28.0137 21.5704 28.4219 21.5992 28.7578 21.4225L32 19.7174L35.2422 21.4225C35.3887 21.4992 35.5488 21.5372 35.708 21.5372C35.915 21.5372 36.1211 21.4727 36.2959 21.3463C36.6035 21.1227 36.7578 20.7433 36.6934 20.3683L36.0743 16.757L38.6983 14.1999C38.9708 13.9338 39.0684 13.5367 38.9512 13.1744Z" fill={isSelected ? '#fff ': '#4C5167'} />
        <path d="M32.0001 21.9807V32.4007C32.0001 33.0607 31.6801 33.6707 31.1401 34.0407C30.8001 34.2807 30.4101 34.4007 30.0001 34.4007C29.7701 34.4007 29.5401 34.3607 29.3101 34.2807L18.3701 30.6707C18.7601 30.1607 19.0001 29.5207 19.0001 28.8307V20.8307C19.0001 20.1507 18.7601 19.5107 18.3701 19.0007L25.0901 16.7807L25.7801 17.4507L25.3401 20.0307C25.1401 21.1607 25.6 22.2907 26.5301 22.9707C27.0601 23.3507 27.6701 23.5407 28.29 23.5407C28.77 23.5407 29.25 23.4207 29.69 23.1907L32.0001 21.9807Z" fill={isSelected ? '#fff ': '#4C5167'} />
        <path d="M17 31.6507V36.8307C17 38.4807 15.65 39.8307 14 39.8307C12.35 39.8307 11 38.4807 11 36.8307V31.7507C11.33 31.8107 11.66 31.8307 12 31.8307H16C16.35 31.8307 16.69 31.7707 17 31.6507Z" fill={isSelected ? '#fff ': '#4C5167'} />
      </g>
    <defs>
      <clipPath id="clip0_2189_9962">
        <rect width="32" height="32" fill={isSelected ? '#3049c5': '#fff'} transform="translate(7 8)"/>
      </clipPath>
      </defs>
    </svg>
  )
}

export default BrandMarketingIcon;
