import React, { useState, useEffect } from 'react';
import { useAlert } from "react-alert";
import $ from "strings/onboard";
import {
  validateUser,
  updateTalentProfile,
  getQualifications
} from '../../utils/api';
import { Formik, Form } from 'formik';
import { PersistFormikValues } from 'formik-persist-values';
import { validationMappings } from './FormMappings';
import {
  ContactInformation,
  EmploymentStatus,
  OpportunityPreferences,
  Availability,
  Experience,
  Specialties,
  Skills,
  Tools,
  Industries,
  OtherExperience,
  Confirmation,
  Loader
} from './screens/index';
import { ActionBar } from "./components/index";
import Navigation from "./Navigation";
import "./index.css";
import AlertError from "components/AlertError";

const Onboard = () => {
  const alert = useAlert();
  const params = new URL(document.location).searchParams;
  const userAuthcode = params.get('user') || null;
  const [isValidated, setIsValidated] = useState(localStorage.getItem('validated') || false);
  const [step, setStep] = useState(parseInt(params.get('step')) || 0);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [talentEmail, setTalentEmail] = useState();
  const [qualifications, setQualifications] = useState();
  const [stepsWithErrors, setStepsWithErrors] = useState([]);

  const onClickNext = () => {
    let newStep = step + 1;
    setStep(newStep);
  }

  const onClickPrev = () => {
    let newStep = step - 1;
    setStep(newStep);
  }

  const onClickNavItem = (navStep) => {
    setStep(navStep);
  }

  const getDefaultHoursExpirationDate = () => {
    let d = new Date();
    d.setDate(d.getDate() + 60);

    return d;
  }

  const fetchQualifications = async () => {
    try {
      const qualificationsResponse = await getQualifications({
        page_size: 9999
      });
      setQualifications(qualificationsResponse.results);
    } catch (e) {
      console.error('Failed to fetch qualifications')
    }
  }

  // start user validation 
  const validateUserToken = async () => {
    try {
      const userResponse = await validateUser(userAuthcode);
      if (userResponse.status === 200) {
        setTalentEmail(
          userResponse.data.primary_talent_email ?
          userResponse.data.primary_talent_email :
          userResponse.data.email ? userResponse.data.email : ''
        )
        window.localStorage.setItem('validated', true);
        setIsValidated(true);
      }
    } catch (e) {
      window.location.assign('https://www.rightsideup.com');
    }
  };

  useEffect(() => {
    fetchQualifications();
    validateUserToken();
  }, []);

  const initialValues = {
      firstName: '',
      lastName: '',
      phone: '',
      schedulingLink: '',
      communicationPreferences: '',
      agreeToPolicies: true,
      agreeToContact: true,
      agreeToTexting: true,
      talentType: null,
      employmentStatus: null,
      workingTimezones: null,
      businessHoursAvailable: null,
      onsiteFrequency: null,
      travelDistancePreferences: null,
      yearsOfExperience: null,
      fulltimeInterest: null,
      specialties: '',
      hoursAvailable: null,
      hoursExpirationDate: getDefaultHoursExpirationDate(),
      minimumRate: null,
      rateConsiderations: '',
      qualificationsRatings: {},
      qualificationsOther: '',
      toolsRatings: {},
      industries: {},
      openToFulltimeFreelance: null,
    }


  const processTalentType = (values) => {
    const { employmentStatus, openToFulltimeFreelance, businessHoursAvailable } = values;
    let talentType;
        
    if (employmentStatus.includes('searching_for_w2') && !openToFulltimeFreelance) {
      talentType = 'FTECandidateOnly'
    } else if (businessHoursAvailable === 'no') {
      talentType = 'Moonlighter'
    } else {
      if (employmentStatus.includes('w2_employee')) {
        talentType = 'FlexibleMoonlighter'
      } else if (!employmentStatus.includes('fulltime_freelancer')) {
        talentType = 'ConditionalFreelancer'
      } else {
        talentType = 'Freelancer'
      }
    }
    
    return talentType;
  }

  const processFTFreelanceInterest = (values) => {
    return values.employmentStatus?.includes('fulltime_freelancer') ? true : values.openToFulltimeFreelance;
  } 

  const handleFormSubmit = async (values) => {
    setFormSubmitting(true);
    let talentType = processTalentType(values);
    let openToFulltimeFreelance = processFTFreelanceInterest(values);
    let phone = values.phone.replace('+1 ', ''); // remove dialcode for US / CA
    let yearsOfExperience = Math.round(values.yearsOfExperience);
    let minimumRate = Math.round(values.minimumRate);
    let hoursAvailable = Math.round(values.hoursAvailable);
    const data = {
      ...values,
      userId: userAuthcode,
      email: talentEmail,
      talentType,
      openToFulltimeFreelance,
      yearsOfExperience,
      minimumRate,
      hoursAvailable,
      phone
    };
    console.info({ data })
    try {
      const response = await updateTalentProfile(data);
      setFormSubmitting(false);
      setFormSubmitted(true);
    } catch (e) {
      console.error({ e, data });
      alert.error(<AlertError error="Something went wrong. Please try again, or contact us if the issue continues." />);
    }
  }

  return (
    <div className="onboardContainer flex flex-col sm:flex-row sm:items-center overflow-y-scroll sm:overflow-y-hidden bg-alice-blue h-screen max-h-screen w-full font-lato">
      {formSubmitted ? 
        <Confirmation /> :
       formSubmitting ? 
        <Loader msg={$.submitting_title} /> :
       (isValidated && initialValues && talentEmail) ? (
        <Formik
          initialValues={initialValues}
          validationSchema={validationMappings[step]}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={() => { return }}
        >
          {({
            setFieldValue,
            values,
            touched,
            setTouched,
            setFieldTouched,
            errors,
            setFieldError,
            validateForm
        }) =>
          <>
            <Navigation
              activeStep={step}
              onClickNavItem={onClickNavItem}
              values={values}
              errors={errors}
              stepsWithErrors={stepsWithErrors}
            />
            <div className="formContainer flex flex-col flex-2 h-screen max-h-screen">
              <Form className="form">
                {{
                  0: <ContactInformation {...{ setFieldValue, values, touched, errors, setFieldError, setFieldTouched, talentEmail }} />,
                  1: <EmploymentStatus {...{ setFieldValue, values, touched, errors, setFieldError }} />,
                  2: <OpportunityPreferences {...{ setFieldValue, values, touched, errors, setFieldError }} />,
                  3: <Availability {...{ setFieldValue, values, touched, errors, setFieldError }} />,
                  4: <Experience {...{ setFieldValue, values, touched, errors, setFieldError }} />,
                  5: <Specialties {...{ setFieldValue, values, touched, errors, setFieldError }} />,
                  6: <Skills {...{ setFieldValue, values, touched, errors, setFieldError, qualifications }} />,
                  7: <Tools {...{ setFieldValue, values, touched, errors, setFieldError, qualifications }} />,
                  8: <Industries {...{ setFieldValue, values, touched, errors, setFieldError, qualifications }} />,
                  9: <OtherExperience {...{ setFieldValue, values, touched, errors }} />
                }[step] || <div />}
                <PersistFormikValues name={`onboard--${userAuthcode}`} />
              </Form>
              <div className="actionBar pb-4 sm:pb-0 sm:sticky sm:bottom-4 w-full flex px-8">
                <ActionBar
                  step={step}
                  values={values}
                  errors={errors}
                  stepsWithErrors={stepsWithErrors}
                  setStepsWithErrors={setStepsWithErrors}
                  onClickNext={onClickNext}
                  onClickPrev={onClickPrev}
                  onClickSubmit={() => handleFormSubmit(values)}
                  validateForm={validateForm}
                  setTouched={setTouched}
                  formSubmitting={formSubmitting}
                />
              </div>
            </div>
          </>}
      </Formik>
      ) : <Loader msg={$.loading_title} />
    }</div>
  )
};

export default Onboard;
