import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  List,
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  InfiniteLoader,
} from "react-virtualized";
import "react-virtualized/styles.css";
import { LoadPlaceholderGroup } from "./LoadPlaceholder";
import { usePageCache } from "contexts/pageCacheContext";

const DEFAULT_LIMIT = 1000;

const scrollCache = new CellMeasurerCache({
  defaultHeight: 273,
  fixedWidth: true,
});

const ListResult = ({
  data,
  setData,
  itemComponent,
  fetch,
  itemParams,
  search,
  total,
  id,
}) => {
  const { set, cache } = usePageCache();
  const [keywordBannerEnabled, setKeywordBannerEnabled] = useState(false);

  const isFirst = useRef(true);
  const scrollIndexRef = useRef();
  const [forceScrollTop, setForceScrollTop] = useState();

  useEffect(() => console.log(scrollCache), [scrollCache])
  useEffect(() => {
    const scrollTop = cache[id + "__scrollIndex"];

    if (scrollTop !== null) {
      set(id + "__scroll", null);
      setForceScrollTop(scrollTop);
    }

    return () => {
      set(id + "__scrollIndex", scrollIndexRef.current);
    };
  }, []);

  useEffect(() => {
    if (forceScrollTop !== undefined) {
      setForceScrollTop(undefined);
    }
  }, [forceScrollTop]);

  const onRenderScroll = useCallback(
    ({ startIndex }) => {
      scrollIndexRef.current = startIndex;
    },
    [scrollIndexRef]
  );

  useEffect(() => {
    scrollCache.clearAll();
  }, [data]);

  useEffect(() => {
    // there's a bug when there's very few search results
    setTimeout(() => {
      scrollCache.clearAll();
    }, 1000);
  }, []);

  useEffect(() => {
    if (!isFirst.current) {
      setForceScrollTop(0);
    } else {
      isFirst.current = false;
    }
  }, [search]);

  const Item = itemComponent;

  const searchMatch = search.length >= 1 ? search[0] : "";

  const isRowLoaded = ({ index }) => !!data[index];

  const loadMoreRows = ({ startIndex, stopIndex }) =>
    fetch(startIndex, stopIndex - startIndex + 1, true);

  return (
    <div className="flex-1">
      <InfiniteLoader
        isRowLoaded={isRowLoaded}
        loadMoreRows={loadMoreRows}
        rowCount={total === null ? 1000 : Math.min(total, DEFAULT_LIMIT)}
      >
        {({ onRowsRendered, registerChild }) => (
          <AutoSizer>
            {({ height, width }) => (
              <List
                onRowsRendered={(s) => {
                  onRenderScroll(s);
                  onRowsRendered(s);
                }}
                scrollToAlignment="start"
                scrollToIndex={forceScrollTop}
                ref={registerChild}
                width={width}
                height={height}
                rowCount={
                  total === null ? 1000 : Math.min(total, DEFAULT_LIMIT)
                }
                deferredMeasurementCache={scrollCache}
                rowHeight={scrollCache.rowHeight}
                rowRenderer={({ key, parent, style, index }) => (
                  <CellMeasurer
                    cache={scrollCache}
                    columnIndex={0}
                    key={key}
                    parent={parent}
                    rowIndex={index}
                  >
                    {({ registerChild }) => (
                      <div ref={registerChild} style={style}>
                        {data[index] ? (
                          <Item
                            index={index}
                            data={data[index]}
                            setData={setData}
                            {...itemParams}
                            searchMatch={searchMatch}
                            search={search}
                            keywordBannerEnabled={keywordBannerEnabled}
                            setKeywordBannerEnabled={setKeywordBannerEnabled}
                          />
                        ) : (
                          <div
                            style={{ height: "273px" }}
                            className="bg-white border-b border-link-water px-6 py-3 w-full"
                          >
                            <LoadPlaceholderGroup />
                          </div>
                        )}
                      </div>
                    )}
                  </CellMeasurer>
                )}
              />
            )}
          </AutoSizer>
        )}
      </InfiniteLoader>
    </div>
  );
};

const ListRenderer = (props) => (props.data ? <ListResult {...props} /> : null);

export default ListRenderer;
