import React from "react";
import { Link } from "react-router-dom";
import { admin_contact as $ } from "strings";
import { toFormat } from "utils/date";
import Header from "components/ListHeader";
import UilCalendar from "@iconscout/react-unicons/icons/uil-calender";
import UilHeartRate from "@iconscout/react-unicons/icons/uil-heart-rate";
import { stageOptions } from "utils/globalConsts";

const Project = ({ project }) => {
  const {
    id,
    project_stage,
    created_at,
    updated_at,
    project_name,
    company_name
  } = project;

  return (
    <div
      id={`referral_project--${id}`}
      className="bg-white flex-1 mt-2 mb-2 flex flex-col"
    >
      <div className="w-full flex space-between px-2">
        <div className="items-center w-1/4 mr-1 truncate">
          <div className="text-sm text-midnight font-bold text-ellipsis font-bold mt-1">
            <Link
              id={`projectLink--${id}`}
              className="inline-block"
              to={`/admin/project/${id}`}
            >
              {project_name}
            </Link>
          </div>
          <div className="text-sm text-midnight font-bold truncate">
            {company_name ||  $.unknown_value}
          </div>
        </div>
        <div className="mt-1 w-1/6">
          <Header Icon={UilHeartRate} text={$.project_stage_label} />
          <div className="text-sm text-midnight font-bold text-ellipsis">
            {stageOptions.find((stage) => stage.value === project_stage).label}
          </div>
        </div>
        <div className="mt-1 w-1/6">
          <Header Icon={UilCalendar} text={$.project_created_label} />
          <div className="text-sm text-midnight font-bold text-ellipsis">
            {created_at ? toFormat(new Date(created_at), "MMMM dd, yyyy") : $.unknown_value}
          </div>
        </div>
        <div className="mt-1 w-1/6">
          <Header Icon={UilCalendar} text={$.project_updated_label} />
          <div className="text-sm text-midnight font-bold text-ellipsis">
            {updated_at ? toFormat(new Date(updated_at), "MMMM dd, yyyy") : $.unknown_value}
          </div>
        </div>
      </div>
    </div>
  )
};

export default Project;
