import { admin_talent_project as $ } from "strings";
import { admin_project_candidate_detail as candidate } from "strings";

export const statusOptions = [
  { label: $.new_option, value: "new" },
  { label: $.maybe_option, value: "maybe" },
  { label: $.hold_option, value: "hold" },
  { label: $.short_list_option, value: "short_list" },
  { label: $.scheduled_option, value: "scheduled" },
  { label: $.submitted_option, value: "submitted" },
  { label: $.intro_call_option, value: "intro_call" },
  { label: $.placed_option, value: "placed" },
  { label: $.pass_option, value: "pass" },
  { label: $.completed_option, value: "completed" },
  { label: $.hired_option, value: "hired" },
];

export const rejectionReasonOptions = [
  { label: candidate.misalignment_option, value: "1" },
  { label: candidate.budget_option, value: "2" },
  { label: candidate.not_interested_option, value: "3" },
  { label: candidate.not_available_option, value: "4" },
  { label: candidate.candidate_not_responsive_option, value: "5" },
  { label: candidate.alternate_selected_option, value: "7" },
  { label: candidate.filled_non_rsu_option, value: "6" },
  { label: candidate.client_not_responsive_option, value: "8" },
  { label: candidate.client_paused_option, value: "9" },
  { label: candidate.not_fit_option, value: "10" },
  { label: candidate.not_evaluated_option, value: "11" },
]
