import React, { useState } from "react";
import { admin_talent_overview as $ } from "strings";
import $$ from "strings/onboard";
import { B2DB } from "components/Typography";
import Section from "components/Section";
import Indicator from "components/ListIndicator";
import UilSetting from "@iconscout/react-unicons/icons/uil-setting";
import UilArrow from "@iconscout/react-unicons/icons/uil-compress-alt";
import Header from "components/ListHeader";
import useCachedFetch from "hooks/useCachedFetch";
import {
  getTalentPreferencesV2,
  patchTalentPreferencesV2
} from "utils/adminApi";
import AvailabilityLastUpdate from "./AvailabilityLastUpdate";
import Select from "components/CompactSelect";
import Input from "components/ListEditable";
import AlertError from "components/AlertError";
import { useAlert } from "react-alert";


const specialtiesOptions = [
  { label: $.performance_marketing_option, value: 'performance_marketing' },
  { label: $.lifecycle_crm_option, value: 'lifecycle_crm' },
  { label: $.content_marketing_option, value: 'content_marketing' },
  { label: $.product_marketing_option, value: 'product_marketing' },
  { label: $.analytics_option, value: 'analytics' },
  { label: $.marketing_ops_option, value: 'marketing_ops' },
  { label: $.affiliate_option, value: 'affiliate' },
  { label: $.ecommerce_option, value: 'ecommerce' },
  { label: $.design_option, value: 'design' },
  { label: $.leadership_option, value: 'leadership' },
  { label: $.brand_marketing_option, value: 'brand_marketing' }
]

const employmentStatusOptions = [
  {label: $$.w2_employee_option, value: 'w2_employee'},
  {label: $$.ft_freelancer_option, value: 'fulltime_freelancer'},
  {label: $$.pt_freelancer_option, value: 'parttime_freelancer'},
  {label: $$.student_option, value: 'student'},
  {label: $$.searching_for_w2_option, value: 'searching_for_w2'},
];

const workingTimezonesOptions = [
  {label: 'Eastern', value: 'eastern'},
  {label: 'Central', value: 'central'},
  {label: 'Mountain', value: 'mountain'},
  {label: 'Pacific', value: 'pacific'}
];

const onsiteFrequencyOptions = [
  {label: $$.regular_days_option, value: 'regular'},
  {label: $$.occasional_meetings_option, value: 'occasional'},
  {label: $$.special_events_option, value: 'special'},
  {label: $$.not_interested_travel_option, value: 'not_interested' }
];

const travelDistanceOptions = [
  {label: $$.local_travel_option, value: 'local'},
  {label: $$.domestic_travel_option, value: 'domestic'},
  {label: $$.international_travel_option, value: 'international'}
];

const rateFlexibilityOptions = [
  {label: $$.passion_project_option, value: 'PassionProject'},
  {label: $$.longer_contract_option, value: 'LongerContract'},
  {label: $$.more_hours_option, value: 'WeeklyWork'}
];

const businessHoursAvailableOptions = [
  {label: $$.yes_option, value: 'yes'},
  {label: $$.depends_on_day_option, value: 'depends_on_day'},
  {label: $$.no_option, value: 'no'}
];

const boolOptions = [
  { value: true, label: $.yes_option },
  { value: false, label: $.no_option },
];

const customMultiSelectStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: state.selectProps.secondary
      ? "#f4f6f9"
      : state.selectProps.isDisabled
      ? "#f4f6f9"
      : "inherit",
    minHeight: "1.5rem",
    lineHeight: "1.5rem",
    width: "100%",
    marginTop: "0",
    marginBottom: "0",
    borderRadius: ".25rem",
    padding: "0",
    display: "flex",
    alignItems: "center",
    boxShadow: "0",
    border: state.selectProps.error
      ? "solid 1.5px #a10000"
      : state.isFocused
      ? "solid 1.5px #0070d2"
      : state.selectProps.secondary
      ? "0"
      : "solid 1px #fff",
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: "#fff",
    borderRadius: ".25rem",
    height: "1.25rem",
    display: "flex",
    alignItems: "center",
    fontWeight: "700",
    fontSize: ".875rem",
    color: "#16325c",
  }),
  valueContainer: (provided) => ({
    ...provided,
    fontWeight: "700",
    fontSize: ".875rem",
    color: "#16325c",
    padding: "0 10px 0 0",
  }),
}

const Preferences = ({ talentData, setTalentData }) => {
  const [open, setOpen] = useState(true);
  const [states, setStates] = useState({});
  const alert = useAlert();

  const { data, setData } = useCachedFetch(
    "admin_talent_preferences",
    getTalentPreferencesV2,
    talentData?.id
  );

  const onChange = async (key, value) => {
    const origVal = data[key];

    setData({ ...data, [key]: value });
    setStates({ ...states, [key]: "saving" });

    const call = async () => {
      try {
        const response = await patchTalentPreferencesV2(talentData?.id, { [key]: value });
        const { preferences_last_updated_at, preferences_last_updated_by } = response;
        setData({ ...data, [key]: value, preferences_last_updated_at, preferences_last_updated_by });
        setTalentData({
          ...talentData,
          'talent_type': response.talent_type,
          'employment_status': response.employment_status,
          'ft_freelance_interest': response.ft_freelance_interest,
          'business_hours_available': response.business_hours_available
        });
        setStates({ ...states, [key]: "saved" });
      } catch (e) {
        setStates({ ...states, [key]: "error" });
        setData({ ...data, [key]: origVal });
        console.error(e);
        alert.error(<AlertError error={e} onRetry={call} />);
      }
    };
    await call();
  }

  const onMultiSelectChange = async (key, values) => {
    const origVal = data[key];
    const newVal = values ? values.map(v => v.value) : [];
    const toSend = { [key]: newVal.flatMap(v => v === null ? [] : [v]) }; // remove null values
    if (key === 'onsite_frequency' && newVal.indexOf('not_interested') > -1) {
      toSend.travel_distance_preferences = null;
      toSend.onsite_frequency = ['not_interested'];
    }
    setData({ ...data, ...toSend });
    setStates({ ...states, [key]: "saving" });

    const call = async () => {
      try {
        const response = await patchTalentPreferencesV2(talentData?.id, toSend);
        setData({ ...response });
        setTalentData({
          ...talentData,
          'talent_type': response.talent_type,
          'employment_status': response.employment_status,
          'ft_freelance_interest': response.ft_freelance_interest,
          'business_hours_available': response.business_hours_available
        })
        setStates({ ...states, [key]: "saved" });
      } catch (e) {
        setStates({ ...states, [key]: "error" });
        setData({ ...data, [key]: origVal });
        console.error(e);
        alert.error(<AlertError error={e} onRetry={call} />);
      }
    };
    await call();
  }

  return (
    <Section id="admin_talent_preferences" className="flex mb-1">
      <div className="bg-white flex-1 mr-2 flex flex-col">
        <div className="flex items-center px-2 py-4">
          <B2DB className="flex flex-1 items-center">
            <UilSetting className="mr-2" size="18" /> {$.preferences_title}
          </B2DB>
          <div className="cursor-pointer" onClick={() => setOpen(!open)}>
            <UilArrow size="18" />
          </div>
        </div>
        {open && !!data && (<>
            <div className="px-2">
              <div className="w-full flex-col">
                <Header text={$.specialties_title}>
                  <Indicator state={states.specialties} />
                </Header>
                <Select
                  className="w-full mb-1"
                  onChange={(v) => onMultiSelectChange("specialties", v)}
                  value={
                    Array.isArray(data.specialties)
                    ? data.specialties.map((v) =>
                        specialtiesOptions.find(
                          (o) => o.value === v
                        )
                      )
                    : null
                  }
                  placeholder={$.select_placeholder}
                  options={specialtiesOptions}
                  customStyles={customMultiSelectStyles}
                  isMulti
                />
                <Header text={$.onsite_frequency_title}>
                  <Indicator state={states.onsite_frequency} />
                </Header>
                <Select
                  className="w-full mb-1"
                  onChange={(v) => onMultiSelectChange("onsite_frequency", v)}
                  value={
                    Array.isArray(data.onsite_frequency)
                    ? data.onsite_frequency.map((v) =>
                        onsiteFrequencyOptions.find(
                          (o) => o.value === v
                        )
                      )
                    : null
                  }
                  placeholder={$.select_placeholder}
                  options={onsiteFrequencyOptions}
                  customStyles={customMultiSelectStyles}
                  isMulti
                />
                <Header text={$.travel_distance_title}>
                  <Indicator state={states.travel_distance_preferences} />
                </Header>
                <Select
                  className="w-full mb-1"
                  onChange={(v) => onMultiSelectChange("travel_distance_preferences", v)}
                  value={
                    Array.isArray(data.travel_distance_preferences)
                    ? data.travel_distance_preferences.map((v) =>
                        travelDistanceOptions.find(
                          (o) => o.value === v
                        )
                      )
                    : null
                  }
                  placeholder={$.select_placeholder}
                  options={travelDistanceOptions}
                  customStyles={customMultiSelectStyles}
                  isMulti
                />
                <Header text={$.rate_flexibility_title}>
                  <Indicator state={states.rate_flexibility} />
                </Header>
                <Select
                  className="w-full mb-1"
                  onChange={(v) => onMultiSelectChange("rate_flexibility", v)}
                  value={
                    Array.isArray(data.rate_flexibility)
                    ? data.rate_flexibility.map((v) =>
                        rateFlexibilityOptions.find(
                          (o) => o.value === v
                        )
                      )
                    : null
                  }
                  placeholder={$.select_placeholder}
                  options={rateFlexibilityOptions}
                  customStyles={customMultiSelectStyles}
                  isMulti
                />
                <Header text={$.employment_status_title}>
                  <Indicator state={states.employment_status} />
                </Header>
                <Select
                  className="w-full mb-1"
                  onChange={(v) => onMultiSelectChange("employment_status", v)}
                  value={
                    Array.isArray(data.employment_status)
                    ? data.employment_status.map((v) =>
                        employmentStatusOptions.find(
                          (o) => o.value === v
                        )
                      )
                    : null
                  }
                  placeholder={$.select_placeholder}
                  options={employmentStatusOptions.map(o => ({
                    ...o,
                    disabled: o.value === 'fulltime_freelancer' && data.employment_status?.indexOf('parttime_freelancer') > -1 ||
                              o.value === 'parttime_freelancer' && data.employment_status?.indexOf('fulltime_freelancer') > -1
                    })
                  )}
                  customStyles={customMultiSelectStyles}
                  isMulti
                />
              </div>
            <div className="w-full flex mb-1">
              <div className="w-1/2">
                <Header text={$.ft_freelance_title}>
                  <Indicator state={states.ft_freelance_interest} />
                </Header>
                <Select
                  className="w-full"
                  onChange={(v) => onChange("ft_freelance_interest", v.value)}
                  value={
                    boolOptions.find((i) => i.value === data.ft_freelance_interest) ||
                    null
                  }
                  placeholder={$.select_placeholder}
                  options={boolOptions}
                  />
              </div>
              <div className="w-1/2">
                <Header text={$.business_hours_available_title}>
                  <Indicator state={states.business_hours_available} />
                </Header>
                <Select
                  className="w-full"
                  onChange={(v) => onChange("business_hours_available", v.value)}
                  value={
                    businessHoursAvailableOptions.find((i) => i.value === data.business_hours_available) ||
                    null
                  }
                  placeholder={$.select_placeholder}
                  options={businessHoursAvailableOptions}
                  customStyles={customMultiSelectStyles}
                />
              </div>
            </div>
            <div className="w-full flex mb-1">
              <div className="w-1/2">
                <Header text={$.years_of_experience_title}>
                  <Indicator state={states.years_of_experience} />
                </Header>
                <Input
                  type="number"
                  text={data.years_of_experience || $.none_set}
                  defaultValue={data.years_of_experience}
                  onChange={(v) => onChange("years_of_experience", parseInt(v))}
                  />
              </div>
              <div className="w-1/2">
                <Header text={$.ft_interest_title}>
                  <Indicator state={states.fulltime_interest} />
                </Header>
                <Select
                  className="w-full"
                  onChange={(v) => onChange("fulltime_interest", v.value)}
                  value={
                    boolOptions.find((i) => i.value === data.fulltime_interest) ||
                    null
                  }
                  placeholder={$.select_placeholder}
                  options={boolOptions}
                  />
              </div>
            </div>
            <div className="w-full flex mb-1">
              <div className="w-1/2">
                <Header text={$.working_timezones_title}>
                  <Indicator state={states.working_timezones} />
                </Header>
                <Select
                  className="w-full"
                  onChange={(v) => onMultiSelectChange("working_timezones", v)}
                  value={
                    Array.isArray(data.working_timezones)
                    ? data.working_timezones.map((v) =>
                        workingTimezonesOptions.find(
                          (o) => o.value === v
                        )
                      )
                    : null
                  }
                  placeholder={$.select_placeholder}
                  options={workingTimezonesOptions}
                  customStyles={customMultiSelectStyles}
                  isMulti
                />
              </div>
            </div>
          </div>
          <div className="ml-2 py-1">
            <AvailabilityLastUpdate
              updatedBy={data.preferences_last_updated_by}
              updatedAt={data.preferences_last_updated_at}
            />
          </div> 
        </>)}
      </div>
    </Section>
  );
};

export default Preferences;
