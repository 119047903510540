import React from "react";

const ErrorIcon = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1975_588)">
        <path d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18Z" fill="#A92900"/>
        <path d="M9.95438 9.00001L12.1774 6.77701C12.2966 6.64905 12.3615 6.47981 12.3584 6.30494C12.3554 6.13007 12.2845 5.96322 12.1608 5.83955C12.0372 5.71588 11.8703 5.64504 11.6955 5.64195C11.5206 5.63887 11.3513 5.70378 11.2234 5.82301L9.00038 8.04601L6.77738 5.82301C6.64942 5.70378 6.48018 5.63887 6.30531 5.64195C6.13044 5.64504 5.96359 5.71588 5.83992 5.83955C5.71625 5.96322 5.64541 6.13007 5.64232 6.30494C5.63924 6.47981 5.70415 6.64905 5.82338 6.77701L8.04638 9.00001L5.82338 11.223C5.69698 11.3496 5.62598 11.5211 5.62598 11.7C5.62598 11.8789 5.69698 12.0504 5.82338 12.177C5.94995 12.3034 6.12151 12.3744 6.30038 12.3744C6.47926 12.3744 6.65082 12.3034 6.77738 12.177L9.00038 9.95401L11.2234 12.177C11.3499 12.3034 11.5215 12.3744 11.7004 12.3744C11.8793 12.3744 12.0508 12.3034 12.1774 12.177C12.3038 12.0504 12.3748 11.8789 12.3748 11.7C12.3748 11.5211 12.3038 11.3496 12.1774 11.223L9.95438 9.00001Z" fill="#EDEBEA"/>
      </g>
      <defs>
        <clipPath id="clip0_1975_588">
          <rect width="18" height="18" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
};

export default ErrorIcon;
