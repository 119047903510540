import React from "react";

const TalentTypeToast = () => {
  return (
    <div className="w-70">
      <div className="flex items-center">
          <span>Talent type is now automatically determined by a talent's Employment Status and Business Hours Available. Update one of these fields in the Preferences section to modify Talent Type.</span>
      </div>
    </div>
  );
};

export default TalentTypeToast;
