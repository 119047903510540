import React from "react";

const ErrorInfoIcon = ({
  isSelected
}) => {
  return (
    <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.725 0H4.275C3.27664 0.000760643 2.31939 0.38681 1.61344 1.07338C0.907492 1.75996 0.510548 2.69093 0.509766 3.66189V12.8525C0.510548 13.8235 0.907492 14.7544 1.61344 15.441C2.31939 16.1276 3.27664 16.5136 4.275 16.5144H13.725C14.7234 16.5136 15.6806 16.1276 16.3866 15.441C17.0925 14.7544 17.4895 13.8235 17.4902 12.8525V3.66189C17.4895 2.69093 17.0925 1.75996 16.3866 1.07338C15.6806 0.38681 14.7234 0.000760643 13.725 0ZM9 12.6371C8.83938 12.6371 8.68237 12.5908 8.54882 12.504C8.41527 12.4172 8.31117 12.2939 8.24971 12.1495C8.18824 12.0052 8.17216 11.8464 8.2035 11.6932C8.23483 11.54 8.31218 11.3993 8.42575 11.2888C8.53933 11.1783 8.68403 11.1031 8.84157 11.0726C8.9991 11.0422 9.16239 11.0578 9.31078 11.1176C9.45917 11.1774 9.58601 11.2786 9.67524 11.4085C9.76448 11.5384 9.81211 11.6911 9.81211 11.8473C9.81289 11.9515 9.79246 12.0548 9.75199 12.1513C9.71153 12.2478 9.65184 12.3355 9.57635 12.4095C9.50086 12.4834 9.41107 12.5421 9.31215 12.5822C9.21323 12.6222 9.10715 12.6429 9 12.6429V12.6371ZM9.81211 9.33423C9.81211 9.5437 9.72655 9.74459 9.57425 9.89271C9.42195 10.0408 9.21538 10.124 9 10.124C8.78461 10.124 8.57805 10.0408 8.42575 9.89271C8.27345 9.74459 8.18789 9.5437 8.18789 9.33423V4.31672C8.18789 4.10725 8.27345 3.90635 8.42575 3.75823C8.57805 3.61012 8.78461 3.5269 9 3.5269C9.21538 3.5269 9.42195 3.61012 9.57425 3.75823C9.72655 3.90635 9.81211 4.10725 9.81211 4.31672V9.33423Z" fill="#A92900"/>
    </svg>
  )
};

export default ErrorInfoIcon;
