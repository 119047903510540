import React, { useState } from "react";
import UilStar from "@iconscout/react-unicons/icons/uil-star";

/**
 * Renders a rating input with stars. Used to rate qualifications.
 * @param {int} value - The value of the input.
 * @param {callback} onChange - Callback to handle value changes.
 */
const RatingInput = ({ value, onChange, disabled }) => {
  const [hover, setHover] = useState();

  const count = hover || value;

  return (
    <span className="text-base font-serif mx-1 flex items-center">
      <span className="flex items-center">
        {[...Array(5)].map((e, i) => (
          <UilStar
            size="20"
            className={
              "cursor-pointer " +
              (count >= i + 1 ? "text-kasmir" : "text-geyser")
            }
            onMouseEnter={() => {if (!disabled) setHover(i + 1)}}
            onMouseLeave={() => {if (!disabled) setHover()}}
            onClick={() => {if (!disabled) onChange(value === i + 1 ? 0 : i + 1)}}
            key={i}
          />
        ))}
      </span>
    </span>
  );
};

export default RatingInput;
