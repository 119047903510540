import React, { useState, useEffect } from "react";
import { useAlert } from "react-alert";
import { admin_project_list as $ } from "strings";
import { B2BLink } from "components/Typography";
import { Button } from "components/Buttons";
import { usePageCache } from "contexts/pageCacheContext";
import { getProjectsV2 } from "utils/adminApi";
import NewProjectForm from "./NewProjectForm";
import NewCompanyForm from "./NewCompanyForm";
import NewContactForm from "./NewContactForm";
import HubspotToast from "components/HubspotToast";
import UilPlus from "@iconscout/react-unicons/icons/uil-plus-circle";
import Section from "components/Section";
import useTable from "hooks/useTable";
import {
  fetchContacts,
  fetchCompanies,
  fetchOwners,
  fetchSupportAssistant,
  fetchSupport,
  fetchOpsOwners,
} from "utils/fetch";
import { getAdminFlags } from "utils/localStorageService";
import { toFormat } from "utils/date";
import manager from "filters/project";
import { stageOptions } from "utils/globalConsts";

const wwOrder = ["morning", "afternoon", "night", "weekend", "anytime"];

const workWindowsOptions = [
  { value: "morning", label: $.morning_option },
  { value: "afternoon", label: $.afternoon_option },
  { value: "night", label: $.night_option },
  { value: "weekend", label: $.weekend_option },
  { value: "anytime", label: $.anytime_option },
];

const moonlighterOptions = [
  { value: 0, label: $.no_option },
  { value: 1, label: $.yes_option },
  { value: 2, label: $.maybe_option },
];

const leadSourceOptions = [
  { value: "Social", label: "Social" },
  { value: "Search", label: "Search" },
  { value: "Referral", label: "Referral" },
  { value: "Events", label: "Events" },
  { value: "Client Expansion", label: "Client Expansion" },
  { value: "Email", label: "Email" },
  { value: "Slack", label: "Slack" },
  { value: "Passive Lead Return", label: "Passive Lead Return" },
  { value: "Other", label: "Other" },
];

const ProjectList = ({ qualifications }) => {
  const alert = useAlert();
  const flags = getAdminFlags();
  const { set } = usePageCache();
  const [modal, setModal] = useState();
  const { Table, tableProps, Filters, filtersProps } = useTable({
    cacheKey: "cache_project_list",
    api: getProjectsV2,
    defaultParams: { ordering: "-last_updated" },
  });

  useEffect(() => {
    set("url_project", "/admin/project");
  }, [set]);

  useEffect(() => {
    document.title = "Pipeline - Project Search";
  }, []);

  const schema = {
    name: {
      show: true,
      renderRow: ({ r }) => (
        <B2BLink
          onClick={() => set(`admin_project_${r.id}_fetch`, r)}
          to={`/admin/project/${r.id}`}
        >
          {r.name}
        </B2BLink>
      ),
    },
    stage: {
      show: true,
      type: "multiselect",
      selectProps: { options: stageOptions },
      renderRow: ({ v }) => (v ? $[`${v}_option`] : ""),
    },
    company__id: {
      needsIdSort: true,
      sortKey: "company__name",
      show: true,
      type: "select",
      selectProps: { async: true, loadOptions: fetchCompanies },
      renderRow: ({ r }) => (r.company && r.company_name ? r.company_name : ""),
    },
    contact__id: {
      sortKey: "contact_name",
      type: "select",
      selectProps: { async: true, loadOptions: fetchContacts },
      renderRow: ({ r }) =>
        r.contact ? r.contact.first_name + " " + r.contact.last_name : "",
    },
    project_owner_project: {
      show: true,
      type: "multiselect",
      sortKey: "owner_primary__first_name",
      selectProps: { async: true, loadOptions: fetchOwners },
      renderRow: ({ r }) => (
        <span>
          {r.owner_primary && <span className="font-bold">{r.owner_primary.first_name + " " + r.owner_primary.last_name}</span>}
          {(r.owner_primary && r.owner_secondary) && <>, </>}
          {r.owner_secondary && <span>{r.owner_secondary.first_name + " " + r.owner_secondary.last_name}</span>}
        </span>
      )
    },
    new_lead_source__source: {
      type: "select",
      selectProps: { options: leadSourceOptions },
      renderRow: ({ r }) => (r.new_lead_source ? r.new_lead_source.source : ""),
    },
    stakeholder_support_id: {
      sortKey: "stakeholder_support_name",
      type: "select",
      selectProps: { async: true, loadOptions: fetchSupport },
      renderRow: ({ r }) => (
        r.project_ces?.length ? r.project_ces.join(", ") : ""
      )
    },
    stakeholder_support_assistant_id: {
      sortKey: "stakeholder_support_assistant_name",
      type: "select",
      selectProps: { async: true, loadOptions: fetchSupportAssistant },
      renderRow: ({ r }) =>
        r.stakeholder_support_assistant &&
        r.stakeholder_support_assistant.first_name
          ? r.stakeholder_support_assistant.first_name +
            " " +
            r.stakeholder_support_assistant.last_name
          : "",
    },
    ops_owner_id: {
      sortKey: "ops_owner_name",
      type: "select",
      selectProps: { async: true, loadOptions: fetchOpsOwners },
      renderRow: ({ r }) => r.ops_owner
    },
    availability_hours: {
      max_param: "availability_hours_maximum",
      needsIdSort: true,
      type: "range_int",
      renderRow: ({ r }) => (
        <>
          {(r.availability_hours || r.availability_hours_maximum) && (
            <>
              {r.availability_hours && r.availability_hours}
              {r.availability_hours && r.availability_hours_maximum && " - "}
              {r.availability_hours_maximum && r.availability_hours_maximum}
              {" " + $.hours_per_week}
            </>
          )}
        </>
      ),
    },
    ideal_rate: {
      max_param: "ideal_rate_maximum",
      needsIdSort: true,
      type: "range_int",
      renderRow: ({ r }) => (
        <>
          {Number.isInteger(r.ideal_rate) && (
            <>
              Min: ${r.ideal_rate}
              {Number.isInteger(r.ideal_rate_maximum) && <br />}
            </>
          )}
          {Number.isInteger(r.ideal_rate_maximum) && (
            <>Max: ${r.ideal_rate_maximum}</>
          )}
        </>
      ),
    },
    maximum_rate: {
      min_param: "minimum_rate",
      needsIdSort: true,
      type: "range_int",
      renderRow: ({ r }) => (
        <>
          {Number.isInteger(r.minimum_rate) && (
            <>
              Min: ${r.maximum_rate}
              {Number.isInteger(r.maximum_rate) && <br />}
            </>
          )}
          {Number.isInteger(r.maximum_rate) && <>Max: ${r.maximum_rate}</>}
        </>
      ),
    },
    work_windows: {
      disableSort: true,
      type: "multiselect",
      selectProps: { options: workWindowsOptions },
      renderRow: ({ v }) =>
        v
          ? v
              .sort(
                (a, b) =>
                  wwOrder.indexOf(a.work_window) -
                    wwOrder.indexOf(b.work_window) ||
                  a.work_window - b.work_window
              )
              .map((w) => $[w.work_window + "_option"])
              .join("/")
          : "",
    },
    moonlighter: {
      needsIdSort: true,
      type: "select",
      selectProps: { options: moonlighterOptions },
      renderRow: ({ v }) =>
        v === 1
          ? $.yes_option
          : v === 2
          ? $.maybe_option
          : v === 0
          ? $.no_option
          : "",
    },
    project_qualifications: {
      disableSort: true,
      type: "select",
      selectProps: { options: qualifications.qualificationsOptions },
      renderRow: ({ v }) => (v ? v.length + " qualifications" : ""),
    },
    candidates_count: { show: true, type: "int" },
    estimated_start_date: {
      type: "range_date",
      renderRow: ({ r }) => (
        r.start_date ? toFormat(new Date(r.start_date), "MM-dd-yyyy")
        : r.estimated_start_date ? `${toFormat(new Date(r.estimated_start_date), "MM-dd-yyyy")}*`
        : null
      ),
      monthly: false,
      disableFilter: true,
      disableSort: true
    },
    estimated_end_date: {
      needsIdSort: true,
      type: "range_date",
      rowFormat: "MM-dd-yyyy",
      monthly: false,
      disableFilter: true,
      disableSort: true
    },
    description: { needsIdSort: true, type: "text" },
    crelate_id: { type: "text", renderRow: ({ v }) => (v ? v : "") },
    timestamp: { show: true, type: "range_date", rowFormat: "MM-dd-yyyy H:mm" },
    last_updated: {
      show: true,
      type: "range_date",
      rowFormat: "MM-dd-yyyy H:mm",
    },
  };

  const onAddClick = () => {
    alert.success(<HubspotToast type='add-project' />, {timeout: 10000});
  }

  return (
    <Section id="admin_project_list">
      <div className="flex">
        <Filters {...{ schema, $, ...filtersProps, manager }} />
        <div className="bg-white px-4 py-6 flex-1 min-w-0">
          <Table
            {...{
              schema,
              $,
              name: "projects",
              ...tableProps,
              showAll: false,
              manager,
            }}
          >
            <Button onClick={() => flags.hubspot_features_enabled ? onAddClick() : setModal("add_project")}>
              <UilPlus size="18" className="mr-1" /> {$.add_project_button}
            </Button>
          </Table>
        </div>
      </div>
      {modal === "add_project" && (
        <NewProjectForm onClose={setModal} setModal={setModal} />
      )}
      {modal === "add_contact" && (
        <NewContactForm onClose={setModal} setModal={setModal} />
      )}
      {modal === "add_company" && (
        <NewCompanyForm onClose={setModal} setModal={setModal} />
      )}
    </Section>
  );
};

export default ProjectList;
