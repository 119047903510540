import React from 'react';

const Tooltip = ({ children, msg }) => {
  return (
    <div className="tooltip" tip={msg}>
      {children}
    </div>
  );
};
  
export default Tooltip;
