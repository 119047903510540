import $ from "strings/onboard";


export const SPECIALTY_OPTIONS = [
  {
    label: $.performance_marketing_option,
    value: 'performance_marketing'
  },
  {
    label: $.lifecycle_crm_option,
    value: 'lifecycle_crm'
  },
  {
    label: $.content_marketing_option,
    value: 'content_marketing'
  },
  {
    label: $.product_marketing_option,
    value: 'product_marketing'
  },
  {
    label: $.analytics_option,
    value: 'analytics'
  },
  {
    label: $.marketing_ops_option,
    value: 'marketing_ops'
  },
  {
    label: $.affiliate_option,
    value: 'affiliate'
  },
  {
    label: $.ecommerce_option,
    value: 'ecommerce'
  },
  {
    label: $.design_option,
    value: 'design'
  },
  {
    label: $.leadership_option,
    value: 'leadership'
  },
  {
    label: $.brand_marketing_option,
    value: 'brand_marketing'
  },
  {
    label: $.offline_option,
    value: 'offline'
  }
]
