import React from "react";
import clsx from "clsx";

const ProductMarketingIcon = ({
  isSelected
}) => {
  return (
    <div className={clsx('rounded-lg p-2', isSelected ? 'bg-electric-indigo' : 'bg-lightest-grey')}>
      <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.3873 17.3058L13.0937 15.0306V13.4055H6.59328C6.46734 13.4055 6.34547 13.4664 6.26826 13.568L3.83061 16.8182C3.73716 16.9401 3.72496 17.1067 3.79403 17.2448C3.85905 17.383 4.00123 17.4683 4.15563 17.4683H11.0623C11.1883 17.4683 11.3102 17.4073 11.3873 17.3058Z" fill={isSelected ? '#fff ': '#4C5167'}/>
        <path d="M23.1694 16.8182L20.7317 13.568C20.6545 13.4664 20.5326 13.4055 20.4067 13.4055H13.9062V15.0306L15.6126 17.3058C15.6898 17.4073 15.8117 17.4683 15.9377 17.4683H22.8443C22.9987 17.4683 23.1409 17.383 23.2059 17.2448C23.275 17.1067 23.2628 16.9401 23.1694 16.8182Z" fill={isSelected ? '#fff ': '#4C5167'}/>
        <path d="M14.9667 17.7974L13.9063 16.3835V21.9374C13.9063 22.1608 13.7235 22.3436 13.5 22.3436C13.2765 22.3436 13.0937 22.1608 13.0937 21.9374V16.3835L12.0374 17.7933C11.8058 18.1021 11.4402 18.2809 11.0623 18.2809H6.18701V23.1562C6.18701 23.8265 6.73549 24.375 7.40583 24.375H19.5942C20.2645 24.375 20.813 23.8265 20.813 23.1562V18.2809H15.9377C15.5598 18.2809 15.1942 18.1021 14.9667 17.7974Z" fill={isSelected ? '#fff ': '#4C5167'}/>
        <path d="M7.56876 8.65732H8.02376V10.6195C8.02376 11.4848 8.72657 12.1876 9.59188 12.1876C10.0713 12.1876 10.4613 11.7976 10.4613 11.3183V8.67357C12.2081 8.76296 13.8981 9.39671 15.2591 10.4976L16.0878 11.1679C16.1609 11.2289 16.2544 11.2573 16.3438 11.2573C16.4047 11.2573 16.4616 11.2451 16.5184 11.2208C16.6606 11.1517 16.75 11.0095 16.75 10.8511V2.03139C16.75 1.87295 16.6606 1.73077 16.5184 1.66575C16.3763 1.5967 16.2097 1.617 16.0878 1.7145L15.2591 2.38482C13.7925 3.57107 11.9441 4.22512 10.055 4.22512C10.0469 4.22512 10.0347 4.22512 10.0266 4.22919H7.56876C6.80907 4.22919 6.1875 4.8467 6.1875 5.61045V7.27607C6.1875 8.03982 6.80908 8.65732 7.56876 8.65732Z" fill={isSelected ? '#fff ': '#4C5167'}/>
        <path d="M18.375 6.44214C18.375 6.66669 18.5567 6.84839 18.7812 6.84839H20.4062C20.6308 6.84839 20.8125 6.66669 20.8125 6.44214C20.8125 6.21759 20.6308 6.03589 20.4062 6.03589H18.7812C18.5567 6.03589 18.375 6.21759 18.375 6.44214Z" fill={isSelected ? '#fff ': '#4C5167'}/>
        <path d="M18.0671 5.12475C18.1711 5.12475 18.275 5.08508 18.3544 5.00573L19.5037 3.85681C19.6624 3.69812 19.6624 3.44104 19.5037 3.28235C19.345 3.12366 19.0879 3.12366 18.9292 3.28235L17.7799 4.43127C17.6212 4.58996 17.6212 4.84704 17.7799 5.00573C17.8593 5.08508 17.9632 5.12475 18.0671 5.12475Z" fill={isSelected ? '#fff ': '#4C5167'}/>
        <path d="M18.9292 9.60168C19.0086 9.68102 19.1125 9.7207 19.2165 9.7207C19.3204 9.7207 19.4243 9.68102 19.5037 9.60168C19.6624 9.44299 19.6624 9.18591 19.5037 9.02722L18.3544 7.87829C18.1957 7.7196 17.9386 7.7196 17.7799 7.87829C17.6212 8.03698 17.6212 8.29407 17.7799 8.45276L18.9292 9.60168Z" fill={isSelected ? '#fff ': '#4C5167'}/>
      </svg>
    </div>
  )
};

export default ProductMarketingIcon;
