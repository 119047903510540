import React from "react";
import $ from "strings/onboard";
import {
  Label,
  Sublabel,
  Input,
  RateInput,
  Checkbox
} from "components/Form";
import { Error } from "../components/index";

const RATE_CONSIDERATIONS_OPTIONS = [
  {label: $.passion_project_option, value: 'PassionProject'},
  {label: $.longer_contract_option, value: 'LongerContract'},
  {label: $.more_hours_option, value: 'WeeklyWork'}
]

const Experience = ({
  setFieldValue,
  values,
  touched,
  onBlur,
  errors,
  setFieldError
}) => {
  const onChange = (e) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
    if (errors[name]) {
      setFieldError(name, null)
    }
  };

  const onCheckboxClick = (fieldName, option, value) => {
    const currentValues = values[fieldName];
    let currentValuesArr = currentValues?.length ? currentValues.split('|') : [];
    if (value === true && currentValuesArr.indexOf(option) === -1) {
      currentValuesArr.push(option);
      if (errors[fieldName]) {
        setFieldError(fieldName, null);
      }
    } else if (value === false && currentValuesArr.indexOf(option) > -1) {
      currentValuesArr.splice(currentValuesArr.indexOf(option), 1)
    }
    const newValues = currentValuesArr.join('|')
    setFieldValue(fieldName, newValues);
  }

  return (
    <div className="px-8 py-4 grow">
      <div className="py-2 mb-auto">
        <div className="text-darkest-navy text-2xl sm:text-3xl font-bold py-2">{$.experience_title}</div>
        <div className="text-midnight text-base sm:text-lg font-normal">{$.experience_subtitle}</div>
      </div>
      <div className="flex flex-col gap-2 py-4">
        <div className="my-px">
          <Label className="w-full sm:w-1/2 font-normal text-darkest-navy text-sm sm:text-base">{$.experience_label}</Label>
          <div className="w-full sm:w-1/2">
            <Input
              type="number"
              name="yearsOfExperience"
              maxLength="100"
              placeholder={$.amount_placeholder}
              onChange={onChange}
              onBlur={onBlur}
              value={values.yearsOfExperience}
              error={errors.yearsOfExperience}
              fontSize={'text-sm sm:text-base'}
            />
          </div>
          {(!touched.yearsOfExperience && errors.yearsOfExperience) ? <Error msg={errors.yearsOfExperience} /> : <div style={{height: '22px'}} />}
        </div>
        <div className="my-px">
          <Label className="w-full sm:w-1/2 font-normal text-darkest-navy text-sm sm:text-base">{$.rate_label}</Label>
          <Sublabel className="w-full sm:w-1/2">{$.rate_sublabel}</Sublabel>
          <div className= "w-full sm:w-1/2">
            <RateInput
              name="minimumRate"
              placeholder={$.amount_placeholder}
              onChange={onChange}
              onBlur={onBlur}
              value={values.minimumRate}
              error={errors.minimumRate}
              fontSize={'text-sm sm:text-base'}
              children={<span className="absolute right-3 top-2 bottom-2">{$.per_hr_label}</span>}
            />
          </div>
          {(!touched.minimumRate && errors.minimumRate) ? <Error msg={errors.minimumRate} /> : <div style={{height: '22px'}} />}
        </div>
        <div className="my-px">
          <Label className="font-normal text-darkest-navy text-sm sm:text-base">{$.rate_considerations_label}</Label>
          <Sublabel>{$.select_all_sublabel}</Sublabel>
          {RATE_CONSIDERATIONS_OPTIONS.map((option) => 
            <Checkbox
              outlined
              key={option.value}
              value={values['rateConsiderations']?.includes(option.value) ? true : false}
              onChange={(v) => onCheckboxClick('rateConsiderations', option.value, v)}
            >
              <div className="ml-1 my-1 font-sm text-darkest-navy font-lato">{option.label}</div>
            </Checkbox>
          )}
        </div>
      </div>
    </div>
  )
};

export default Experience;
