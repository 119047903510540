import React from "react";
import clsx from "clsx";

const ContentMarketingIcon = ({
  isSelected
}) => {
  return (
    <div className={clsx('rounded-lg p-2', isSelected ? 'bg-electric-indigo' : 'bg-lightest-grey')}>
      <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.0006 6.09375H4.15625C2.81156 6.09375 1.71875 7.18656 1.71875 8.53125V10.1562H17.2822L19.0006 6.09375ZM4.96875 9.34375C3.90137 9.32319 3.90153 7.7391 4.96879 7.71875C6.03613 7.73927 6.03597 9.32336 4.96875 9.34375ZM7.40625 9.34375C6.33887 9.32319 6.33903 7.7391 7.40629 7.71875C8.47363 7.73927 8.47347 9.32336 7.40625 9.34375ZM9.84375 9.34375C8.77637 9.32319 8.77653 7.7391 9.84379 7.71875C10.9111 7.73927 10.911 9.32336 9.84375 9.34375Z" fill={isSelected ? '#fff ': '#4C5167'} />
        <path d="M24.4688 6.71533L23.0144 10.1563H25.2813V8.53127C25.2813 7.81627 24.9725 7.16221 24.4688 6.71533Z" fill={isSelected ? '#fff ': '#4C5167'} />
        <path d="M20.7272 15.5675C20.4411 16.4307 18.9877 17.5297 18.375 18.1675C17.6726 18.9019 16.3314 18.6881 15.8522 17.8019H5.375C5.15156 17.8019 4.96875 17.6231 4.96875 17.3956C4.96875 17.1722 5.15156 16.9894 5.375 16.9894H15.6369L15.6084 14.82C15.5187 13.8662 16.6263 11.8471 16.9409 10.9688H1.71875V21.125C1.71875 22.4697 2.81156 23.5625 4.15625 23.5625H22.8438C24.1884 23.5625 25.2812 22.4697 25.2812 21.125V10.9688H22.6691L20.7272 15.5675ZM5.375 14.4219H13.5C14.031 14.4313 14.0358 15.224 13.5 15.2344H5.375C4.84399 15.2249 4.83924 14.4323 5.375 14.4219ZM20.8125 20.3734H5.375C4.84635 20.365 4.83688 19.5687 5.37504 19.5609H20.8125C21.3406 19.5667 21.3512 20.3629 20.8125 20.3734Z" fill={isSelected ? '#fff ': '#4C5167'} />
        <path d="M16.6118 13.8288C16.2779 14.4566 16.4818 16.3051 16.4493 17.0017C16.4313 17.6828 17.3365 18.0785 17.8184 17.5785C18.3017 17.06 19.7575 15.9413 19.9796 15.2507L20.1827 14.7713L16.8312 13.3088L16.6118 13.8288Z" fill={isSelected ? '#fff ': '#4C5167'} />
        <path d="M20.4998 14.0238L24.3226 4.97665C24.7254 4.08254 24.2664 2.94333 23.3516 2.58384C22.4254 2.18978 21.3489 2.62446 20.9548 3.55478L17.1482 12.5613L20.4998 14.0238Z" fill={isSelected ? '#fff ': '#4C5167'} />
      </svg>
    </div>
  )
};

export default ContentMarketingIcon;
