import React, { useState, useEffect, useMemo } from "react";
import clsx from "clsx";
import { admin_project as $ } from "strings";
import { NavLink, Switch, Route, useParams, Link, Redirect, useHistory } from "react-router-dom";
import { usePageCache } from "contexts/pageCacheContext";
import { BackLinkBtn, HubspotButton } from "components/Buttons";
import {
  patchProjectV2,
  getProjectV2,
  postProjectSeniorityV2,
  deleteProjectSeniorityV2,
  getSenioritiesList
} from "utils/adminApi";
import Section from "components/Section";
import { toFormat } from "utils/date";
import useCachedFetch from "hooks/useCachedFetch";
import { LoadPlaceholderGroup } from "components/LoadPlaceholder";
import { useAlert } from "react-alert";
import AlertError from "components/AlertError";
import HubspotToast from "components/HubspotToast";
import Header from "components/ListHeader";
import Input from "components/ListEditable";
import Indicator from "components/ListIndicator";
import UilBriefcaseAlt from "@iconscout/react-unicons/icons/uil-briefcase-alt";
import UilClock from "@iconscout/react-unicons/icons/uil-clock";
import Select from "components/CompactSelect";
import UilUserCheck from "@iconscout/react-unicons/icons/uil-user-check";
import UilAward from "@iconscout/react-unicons/icons/uil-award";
import UilBill from "@iconscout/react-unicons/icons/uil-bill";
import UilCalender from "@iconscout/react-unicons/icons/uil-calender";
import UilFolderPlus from "@iconscout/react-unicons/icons/uil-folder-plus";
import UilHeartRate from "@iconscout/react-unicons/icons/uil-heart-rate";
import UilLink from "@iconscout/react-unicons/icons/uil-link";
import DatePicker from "components/ListDatePicker";
import Rating from "components/Rating";
import DeleteProjectBtn from "./DeleteProjectBtn";
import DeleteForm from "../DeleteForm";
import Overview from "./Overview/index.js";
import Notes from "./Notes/index.js";
import Stakeholders from "./Stakeholders";
import Attachments from "./Attachments";
import Requirements from "./Requirements";
import Finance from "./Finance/index.js";
import Candidates from "./Candidates/index.js";
import Clockify from "./Clockify/index.js";
import { getAdminFlags } from "utils/localStorageService";
import { withHttp } from "utils/str";
import UilUser from "@iconscout/react-unicons/icons/uil-user";
import { stageOptions as stages } from "utils/globalConsts";

const grades = ["A+", "A", "A-", "B+", "B", "B-", "C"];
const gradeOptions = grades.map((g, i) => ({
  label: g,
  value: 8 - i,
}));

const customMultiSelectStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: state.selectProps.secondary
      ? "#f4f6f9"
      : state.selectProps.isDisabled
      ? "#f4f6f9"
      : "inherit",
    minHeight: "1.5rem",
    lineHeight: "1.5rem",
    width: "100%",
    marginTop: "0",
    marginBottom: "0",
    borderRadius: ".25rem",
    padding: "0",
    display: "flex",
    alignItems: "center",
    boxShadow: "0",
    border: state.selectProps.error
      ? "solid 1.5px #a10000"
      : state.isFocused
      ? "solid 1.5px #0070d2"
      : state.selectProps.secondary
      ? "0"
      : "solid 1px #fff",
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: "#fff",
    borderRadius: ".25rem",
    height: "1.25rem",
    display: "flex",
    alignItems: "center",
    fontWeight: "700",
    fontSize: ".875rem",
    color: "#16325c",

  }),
  valueContainer: (provided) => ({
    ...provided,
    fontWeight: "700",
    fontSize: ".875rem",
    color: "#16325c",
    padding: "0 10px 0 0",
  }),
}
const HUBSPOT_BASE_URL =
  process.env.NODE_ENV === 'development' || process.env.REACT_APP_API_URL.includes('staging') ?
  "https://app.hubspot.com/contacts/45416535/record/0-3" :
  "https://app.hubspot.com/contacts/39598951/record/0-3"


const ProjectPage = () => {
  const history = useHistory();
  const [modal, setModal] = useState();
  const [states, setStates] = useState({});
  const [selectedSeniorities, setSelectedSeniorities] = useState([]);
  const alert = useAlert();
  const { set, cache } = usePageCache();
  const { id } = useParams();
  const flags = getAdminFlags();
  const [stage, setStage] = useState(
    cache["admin_project_overview_note_stage__" + id]
  );
  const { data, setData, pageState } = useCachedFetch(
    "admin_project_v2",
    getProjectV2,
    id
  );

  const seniorities = useCachedFetch(
    "admin_seniority_options",
    getSenioritiesList,
    id
  );

  const seniorityOptions = useMemo(() => {
    const options = [];
    if (seniorities.data && Array.isArray(seniorities.data.results)) {
      seniorities.data.results.forEach((s) => {
        options.push({
          label: s.name,
          value: s.id
        })
      });
    }

    return options;
  }, [seniorities]);

  const stageOptions = useMemo(() => {
    if (!flags) return;
    if (!flags.hubspot_features_enabled) return stages;

    let stagesArr = [];
    stages.forEach((s) => {
      if (s.value === 'matching_no_support' || s.value === 'sourcing') {
        stagesArr.push(s)
      } else {
        stagesArr.push({ ...s, disabled: true })
      }
    })
    return stagesArr;
  }, [flags]);

  useEffect(() => {
    if (stage !== data?.stage) {
      setStage(null);
    }
  }, []);

  useEffect(() => {
    set("admin_project_overview_note_stage__" + id, stage);
  }, [stage, id]);

  useEffect(() => {
    if (data?.seniorities) {
      setSelectedSeniorities(data.seniorities.map(s => (
        { 'value': s.seniority, 'label': s.name }
      )));
    }
  }, [data]);

  let defaultName = data && data.name ? data.name : "";
  const nameSplit = defaultName.split(" - ");
  if (nameSplit && nameSplit.length === 2) {
    defaultName = nameSplit[1];
  }

  const companyName = data && data.company_name ? data.company_name : "";
  useEffect(() => {
    document.title = "Pipeline - " + defaultName + " - " + companyName;
  }, [defaultName, companyName]);

  const onChange = async (key, value, dataValue) => {
    const origVal = data[key];

    setData({ ...data, [key]: dataValue || value });
    setStates({ ...states, [key]: "saving" });

    const call = async () => {
      try {
        await patchProjectV2(data.id, { [key]: value });
        setStates({ ...states, [key]: "saved" });
      } catch (e) {
        setStates({ ...states, [key]: "error" });
        setData({ ...data, [key]: origVal });
        console.error(e);
        alert.error(<AlertError error={e} onRetry={call} />);
      }
    };
    await call();
  };

  const onSaveSeniorities = async () => {
    if (states.seniorities === "saving") return;
    setStates({ ...states, "seniorities": "saving" });

    const origVal = data.seniorities;
    const oldSeniorityIds = origVal.map(s => s.seniority);
    const newSeniorityIds = selectedSeniorities ? selectedSeniorities.map(s => s.value) : [];

    const toDelete = oldSeniorityIds.filter((q) => !newSeniorityIds.includes(q));
    const toCreate = newSeniorityIds.filter((q) => !oldSeniorityIds.includes(q));

    const call = async () => {
      try {
        let senioritiesData = [...data.seniorities];

        for (let s of toDelete) {
          const projectSeniorityId = origVal.find(val => s === val.seniority).id;
          if (!projectSeniorityId) continue;
          await deleteProjectSeniorityV2(projectSeniorityId);
          senioritiesData = senioritiesData.filter(s => s.id !== projectSeniorityId);
        }

        for (let s of toCreate) {
          if (!s) continue;
          const newSeniority = await postProjectSeniorityV2({
            seniority: s,
            project: id
          });
          senioritiesData.push(newSeniority);
        }

        setStates({ ...states, "seniorities": "saved" });
        setData({...data, "seniorities": senioritiesData})
      } catch (e) {
        setStates({ ...states, "seniorities": "error" });
        setData({ ...data, "seniorities": origVal });
        console.error(e);
        alert.error(<AlertError error={e} onRetry={call} />);
      }
    };
    await call();
  }

  const onStageChange = async (value) => {
    if (
      value !== data.stage &&
      [
        "filled",
        "shadow_pitched",
        "matching_support",
        "submitted",
        "lost",
        "passive",
        "completed",
      ].includes(value)
    ) {
      setStage(value);
      history.push(`/admin/project/${id}/notes`);
    } else {
      onChange("stage", value);
    }
  };

  const onInputClick = () => {
    if (flags.hubspot_features_enabled) {
      onEditClick();
      return;
    }
  }

  const onEditClick = () => {
    alert.success(<HubspotToast type='project' objectId={data.hubspot_deal_id} />, {timeout: 10000});
  }

  return (
    <Section id="admin_project" className="h-full flex flex-col">
      <div className="flex items-center justify-between -mt-1">
        <BackLinkBtn to="/admin/project" text={$.back_project} />
        {!!data && (
          <div className="text-kasmir font-light text-xs text-right">
            {$.created_at_label}{" "}
            <span className="lowercase">
              {!!data.timestamp &&
                toFormat(new Date(data.timestamp), "MM/dd/yyyy h:mma")}
            </span>
            <br />
            {$.updated_at_label}{" "}
            <span className="lowercase">
              {!!data.last_updated &&
                toFormat(new Date(data.last_updated), "MM/dd/yyyy h:mma")}
            </span>
          </div>
        )}
      </div>
      <div className="bg-white px-4">
        {pageState === "loading" || !data ? (
          <div className="p-2">
            <LoadPlaceholderGroup />
          </div>
        ) : (
          <>
            <div className="flex flex-col md:flex-row">
              <div className="w-full md:w-1/3">
                <div className="w-full pb-px mb-2" onClick={onInputClick}>
                  <Header Icon={UilBriefcaseAlt} text={$.name_title}>
                    <Indicator state={states.name} />
                  </Header>
                  <div className="flex items-center min-h-6 max-h-12">
                    <Input
                      fullWidth
                      dynamicHeight
                      type="text"
                      text={data.name}
                      defaultValue={defaultName}
                      disabled={flags.hubspot_features_enabled}
                      onChange={(v) => {
                        if (v) {
                          onChange("name", companyName + " - " + v);
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="w-full flex flex-col md:flex-row">
                  <div className="w-full md:w-1/2 md:flex-row">
                    <div className="md:h-1/2 mb-1">
                      <Header Icon={UilHeartRate} text={$.stage_title}>
                        <Indicator state={states.stage} />
                      </Header>
                      <div
                        className="flex items-center"
                        onClick={e => {
                          if (flags.hubspot_features_enabled && e.target.ariaDisabled) {
                            onEditClick();
                          }
                        }}>
                        {!stage ? (
                          <Select
                            className={"-ml-1 w-full"}
                            value={
                              data.stage
                                ? stageOptions.find((o) => o.value === data.stage)
                                : { label: $.none_set, value: null }
                            }
                            onChange={(v) => onStageChange(v ? v.value : null)}
                            options={stageOptions}
                          />
                        ) : (
                          <div className="text-sm font-bold text-kasmir">
                            {stageOptions.find((o) => o.value === stage).label}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="md:h-1/2 mb-1" onClick={onInputClick}>
                      <Header Icon={UilBill} text={$.ideal_rate_title}>
                        <Indicator
                          state={states.minimum_rate || states.maximum_rate}
                        />
                      </Header>
                      <div className="flex items-center h-6">
                        <Input
                          type="number"
                          text={
                            Number.isInteger(Number(data.minimum_rate))
                              ? "$" + data.minimum_rate
                              : $.none_set
                          }
                          defaultValue={data.minimum_rate}
                          onChange={(v) => {
                            onChange("minimum_rate", parseInt(v));
                          }}
                          disabled={flags.hubspot_features_enabled}
                        />
                        <span className="mx-1">-</span>
                        <Input
                          type="number"
                          text={
                            Number.isInteger(Number(data.maximum_rate))
                              ? "$" + data.maximum_rate
                              : $.none_set
                          }
                          defaultValue={data.maximum_rate}
                          onChange={(v) => {
                            onChange("maximum_rate", parseInt(v));
                          }}
                          disabled={flags.hubspot_features_enabled}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="w-full md:w-1/2" onClick={onInputClick}>
                    <div className="md:h-1/2 mb-1">
                      <Header Icon={UilCalender} text={$.start_date_title}>
                        <Indicator state={states.estimated_start_date} />
                      </Header>
                      <DatePicker
                        wrapperClassName="mt-1"
                        placeholderText={$.none_set}
                        value={
                          data && data.start_date
                          ? new Date(data.start_date)
                          : data && data.estimated_start_date
                          ? new Date(data.estimated_start_date)
                          : null
                        }
                        format="MMMM dd, yyyy"
                        monthly={false}
                        disabled={flags.hubspot_features_enabled}
                        onChange={(v) =>
                          onChange(
                            "estimated_start_date",
                            toFormat(v, "yyyy-MM-dd'T'hh:mm")
                            )
                          }
                        />
                    </div>
                    <div className="md:h-1/2 mb-1">
                      <Header Icon={UilBill} text={$.pay_rate_title}>
                        <Indicator
                          state={states.ideal_rate || states.ideal_rate_maximum}
                        />
                      </Header>
                      <div className="flex items-center h-6">
                        <Input
                          type="number"
                          text={
                            Number.isInteger(Number(data.ideal_rate))
                              ? "$" + data.ideal_rate
                              : $.none_set
                          }
                          defaultValue={data.ideal_rate}
                          onChange={(v) => {
                            onChange("ideal_rate", parseInt(v));
                          }}
                          disabled={flags.hubspot_features_enabled}
                        />
                        <span className="mx-1">-</span>
                        <Input
                          type="number"
                          text={
                            Number.isInteger(Number(data.ideal_rate_maximum))
                              ? "$" + data.ideal_rate_maximum
                              : $.none_set
                          }
                          defaultValue={data.ideal_rate_maximum}
                          onChange={(v) => {
                            onChange("ideal_rate_maximum", parseInt(v));
                          }}
                          disabled={flags.hubspot_features_enabled}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-1/4 flex flex-col" onClick={onInputClick}>
                <div className="w-full mb-1">
                  <Header Icon={UilClock} text={$.availability_hours_title}>
                    <Indicator state={states.availability_hours || states.availability_hours_maximum} />
                  </Header>
                  <div className="flex items-center h-6">
                    <Input
                      type="number"
                      text={
                        data.availability_hours > 0
                          ? data.availability_hours
                          : "0"
                      }
                      defaultValue={data.availability_hours || 0}
                      onChange={(v) => {
                        onChange("availability_hours", parseInt(v));
                      }}
                      disabled={flags.hubspot_features_enabled}
                    />
                    <span className="mx-1">-</span>
                    <Input
                      type="number"
                      text={
                        (data.availability_hours_maximum > 0
                          ? data.availability_hours_maximum
                          : "0") +
                        " hours per week"
                      }
                      defaultValue={data.availability_hours_maximum || 0}
                      onChange={(v) => {
                        onChange("availability_hours_maximum", parseInt(v));
                      }}
                      disabled={flags.hubspot_features_enabled}
                    />
                  </div>
                </div>
                <div className="w-full mb-1">
                  <Header Icon={UilUserCheck} text={$.grade_title}>
                    <Indicator state={states.talent_grade} />
                  </Header>
                  <div className="flex items-center">
                    <Select
                      className={"-ml-1 " + (data.talent_grade ? "w-8" : "w-28")}
                      value={
                        data.talent_grade
                          ? {
                              label: grades[8 - data.talent_grade],
                              value: data.talent_grade,
                            }
                          : { label: $.none_set, value: null }
                      }
                      onChange={(v) =>
                        onChange("talent_grade", v ? v.value : null)
                      }
                      options={gradeOptions}
                      isDisabled={flags.hubspot_features_enabled}
                    />
                  </div>
                </div>
                <div className="w-full flex flex-col mt-1">
                  <Header Icon={UilFolderPlus} text={$.seniority_title}>
                    <Indicator state={states.seniorities} />
                  </Header>
                  <div className="flex items-center">
                    {flags.hubspot_features_enabled ?
                      <div className={clsx("text-sm text-midnight my-px", selectedSeniorities.length ? "font-bold" : "font-normal")} >
                        {selectedSeniorities.length ? selectedSeniorities.map((s, i) => `${s.label}${(i !== selectedSeniorities.length - 1) ? ', ' : ''}`) : $.none_set}
                      </div> :
                      <Select
                        isMulti
                        size={6}
                        onBlur={() => onSaveSeniorities()}
                        disabled={states.seniorities === 'saving'}
                        className={"-ml-1 w-full min-w-48 max-w-full"}
                        value={selectedSeniorities}
                        onChange={(v) => setSelectedSeniorities(v.length ? v : [])}
                        options={seniorityOptions}
                        placeholder={$.none_set}
                        customStyles={customMultiSelectStyles}
                      />
                    }
                </div>
              </div>
            </div>
              <div className="w-full md:w-1/4">
                <div className="w-full flex flex-col md:flex-row">
                  <div className="w-full">
                    <Header Icon={UilAward} text={$.requirements_title}>
                      <Indicator state={states.requirements} />
                    </Header>
                    {(!data.top_requirements || !data.top_requirements?.length) && (
                      <Link
                        className="text-sm text-cove underline font-thin"
                        to={`/admin/project/${id}/requirements`}
                      >
                        {$.add_requirement_link}
                      </Link>
                    )}
                    {!!data.top_requirements && (
                      <>
                        <div className="font-bold text-sm">
                          {data.top_requirements.map((q) => (
                            <div key={q.id} className="flex items-center mb-2">
                              <div className="w-1/2 text-midnight">{q.name}</div>
                              <div className="flex-1 flex items-center text-kasmir">
                                <Rating count={q.rating} />
                              </div>
                            </div>
                          ))}
                        </div>
                        <Link
                          className="text-sm font-bold text-kasmir underline"
                          to={`/admin/project/${id}/requirements`}
                        >
                          {$.all_qualifications_link}
                        </Link>
                      </>
                    )}
                    {!!data.top_requirements && data.top_requirements.length >= 5 && (
                      <Link
                        className="text-sm text-cove underline font-thin mb-1"
                        to={`/admin/project/${id}/requirements`}
                      >
                        {$.view_requirements_link}
                      </Link>
                    )}
                  </div>
                </div>
              </div>
              <div className="w-full md:w-1/6">
                <Header Icon={UilUser} text={$.owners_title} />
                {(data.owner_primary || data.owner_secondary) ? (
                  <div className="text-sm text-midnight truncate mb-2">
                    {data.owner_primary && (<span className="font-bold">{data.owner_primary.first_name} {data.owner_primary.last_name}</span>)}
                    {(data.owner_primary && data.owner_secondary) ? ', ' : ''}
                    {data.owner_secondary ? `${data.owner_secondary.first_name} ${data.owner_secondary.last_name}` : ''}
                  </div>
                ) : (
                  <Link
                    className="text-sm font-bold text-kasmir underline mb-2"
                    to={`/admin/project/${id}/stakeholders`}
                  >
                    {$.add_owner_link}
                  </Link>
                )}
                <Header Icon={UilUser} text={$.ce_title} />
                {Array.isArray(data.project_ces) && data.project_ces.length > 0 ? (
                  <div className="text-sm text-midnight font-bold truncate mb-2">
                    {data.project_ces.join(", ")}
                  </div>
                ) : (
                  <Link
                    className="text-sm font-bold text-kasmir underline mb-2"
                    to={`/admin/project/${id}/stakeholders`}
                  >
                    {$.add_ce_link}
                  </Link>
                )}
                <Header Icon={UilUser} text={$.ops_title} />
                {data.ops_owner ? (
                  <div className="text-sm text-midnight font-bold truncate mb-2">
                    {data.ops_owner}
                  </div>
                ) : (
                  <Link
                    className="text-sm font-bold text-kasmir underline mb-2"
                    to={`/admin/project/${id}/stakeholders`}
                  >
                    {$.add_ops_link}
                  </Link>
                )}
              </div>
            </div>
          </>
        )}
      </div>
      <div className="flex w-full">
        <div className="w-full flex flex-row flex-shrink-0 border-t border-b border-link-lighter">
          <div className="flex flex-1">
            <NavLink
              exact
              to={`/admin/project/${id}`}
              className="text-sm px-2 font-medium h-5 flex items-center text-kasmir border-l border-caded"
              activeClassName="font-bold"
            >
              {$.overview_link}
            </NavLink>
            <NavLink
              exact
              to={`/admin/project/${id}/notes`}
              className="text-sm px-2 font-medium h-5 flex items-center text-kasmir border-l border-caded"
              activeClassName="font-bold"
            >
              {$.notes_link}
            </NavLink>
            <NavLink
              to={`/admin/project/${id}/requirements`}
              className="text-sm px-2 font-medium h-5 flex items-center text-kasmir border-l border-caded"
              activeClassName="font-bold"
            >
              {$.requirements_link}
            </NavLink>
            <NavLink
              to={`/admin/project/${id}/candidates`}
              className="text-sm px-2 font-medium h-5 flex items-center text-kasmir border-l border-caded"
              activeClassName="font-bold"
            >
              {$.candidates_link}
            </NavLink>
            <NavLink
              to={`/admin/project/${id}/attachments`}
              className="text-sm px-2 font-medium h-5 flex items-center text-kasmir border-l border-caded"
              activeClassName="font-bold"
            >
              {$.attachments_link} {data?.attachments_count > 0 ? ` (${data.attachments_count})` : ''}
            </NavLink>
            <NavLink
              to={`/admin/project/${id}/stakeholders`}
              className="text-sm px-2 font-medium h-5 flex items-center text-kasmir border-l border-caded last:border-r"
              activeClassName="font-bold"
            >
              {$.stakeholders_link}
            </NavLink>
            {!flags.hubspot_features_enabled && <NavLink
              to={
                cache["url_projects_finance"] || `/admin/project/${id}/finance`
              }
              className="text-sm px-2 font-medium h-5 flex items-center text-kasmir border-l border-caded last:border-r"
              activeClassName="font-bold"
            >
              {$.finance_link}
            </NavLink>}
            {flags.clockify_project_enabled && data && (data.stage === "completed" || data.stage === "filled") && (
              <NavLink
                to={
                  cache["url_projects_clockify"] ||
                  `/admin/project/${id}/clockify`
                }
                className="text-sm px-2 font-medium h-5 flex items-center text-kasmir border-l border-caded last:border-r"
                activeClassName="font-bold"
              >
                {$.clockify_link}
              </NavLink>
            )}
          </div>
          <div className="flex items-center gap-2">
            {(flags.hubspot_features_enabled && data?.hubspot_deal_id) &&
               <HubspotButton to={{pathname: withHttp(`${HUBSPOT_BASE_URL}/${data?.hubspot_deal_id}`)}}>
                <UilLink size="16" className="mr-1" />
                {$.view_in_hubspot_btn}
              </HubspotButton>
            }
            <div className="flex items-center">
              <DeleteProjectBtn onClick={() => flags.hubspot_features_enabled ? onEditClick() : setModal("delete")}>
                {$.delete_btn}
              </DeleteProjectBtn>
            </div>
          </div>
        </div>
      </div>
      <div className="flex-1 min-h-0">
        <Switch>
          <Route exact path="/admin/project/:id">
            <Overview
              data={data}
              stage={stage}
              setStage={setStage}
              setData={setData}
              hubspotInputsDisabled={flags.hubspot_features_enabled}
              onDisabledClick={onEditClick}
            />
          </Route>
          <Route exact path="/admin/project/:id/notes">
            <Notes
              data={data}
              stage={stage}
              setStage={setStage}
              setData={setData}
              hubspotInputsDisabled={flags.hubspot_features_enabled}
              onDisabledClick={onEditClick}
            />
          </Route>
          <Route exact path="/admin/project/:id/stakeholders">
            <Stakeholders
              data={data}
              setData={setData}
              hubspotInputsDisabled={flags.hubspot_features_enabled}
              onDisabledClick={onEditClick}
            />
          </Route>
          <Route exact path="/admin/project/:id/attachments">
            <Attachments
              project={data}
              setData={setData}
              hubspotInputsDisabled={flags.hubspot_features_enabled}
              onDisabledClick={onEditClick}
            />
          </Route>
          <Route exact path="/admin/project/:id/requirements">
            <Requirements
              project={data}
              setData={setData}
              hubspotInputsDisabled={flags.hubspot_features_enabled}
              onDisabledClick={onEditClick}
            />
          </Route>
          {!flags.hubspot_features_enabled && (
            <Route exact path="/admin/project/:id/finance">
              <Finance data={data} setData={setData} />
            </Route>
          )}
          <Route exact path="/admin/project/:id/candidates">
            <Candidates
              project={data}
              hubspotInputsDisabled={flags.hubspot_features_enabled}
              onDisabledClick={onEditClick}
            />
          </Route>
          {flags.clockify_project_enabled && (
            // TO DO: pass data / setData props here again when v2 is reintroduced
            <Route exact path="/admin/project/:id/clockify">
              <Clockify />
            </Route>
          )}
        </Switch>
      </div>
      {(modal === "delete" && !flags.hubspot_features_enabled) && <DeleteForm data={data} onClose={setModal} />}
    </Section>
  );
};

export default ProjectPage;
