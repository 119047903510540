import React from "react";
import UilCamera from "@iconscout/react-unicons/icons/uil-camera-plus";
import UilPen from "@iconscout/react-unicons/icons/uil-pen";
import UilTrash from "@iconscout/react-unicons/icons/uil-trash-alt";
import UilPlus from "@iconscout/react-unicons/icons/uil-plus-circle";
import UilDown from "@iconscout/react-unicons/icons/uil-angle-down";
import UilUp from "@iconscout/react-unicons/icons/uil-angle-up";
import UilLeft from "@iconscout/react-unicons/icons/uil-angle-left-b";
import { Link } from "react-router-dom";
import UilTimes from "@iconscout/react-unicons/icons/uil-times";
import UilImport from "@iconscout/react-unicons/icons/uil-import";
import UilSync from "@iconscout/react-unicons/icons/uil-sync";
import UilCheck from "@iconscout/react-unicons/icons/uil-check-circle";
import UilSave from "@iconscout/react-unicons/icons/uil-save";
import { global as $ } from "strings";
import UilLink from "@iconscout/react-unicons/icons/uil-link";

/**
 * Renders a close button with a times icon. It is used in some preview panels.
 * @param {callback} onClick - A callback to trigger when clicked.
 */
const CloseBtn = ({ onClick }) => (
  <button onClick={onClick} className="group focus:outline-none w-8 h-8">
    <span
      className="group-focus:ring focus:outline-none rounded text-lg w-8 h-8 text-cove inline-flex items-center justify-center hover:bg-link-water active:bg-geyser"
      tabIndex="-1"
    >
      <UilTimes size="24" />
    </span>
  </button>
);

/**
 * Renders a toggler button. Used in to toggle filters.
 * @param {component} children - Children to render, probably just the button text.
 * @param {bool} active - Flag to indicate the button state.
 * @param {string} className - Classes to append to the button default classes.
 * @param {object} props - Extra props to set to the button.
 */
const Toggler = ({ children, active, className = "", ...props }) => (
  <button
    type={props.type || "button"}
    {...props}
    className={"group focus:outline-none h-10 " + className}
  >
    <span
      className={
        "group-focus:ring focus:outline-none text-sm font-bold rounded h-10 inline-flex items-center text-midnight justify-center px-4 bg-transparent " +
        (active ? "bg-link-water hover:bg-link-lighter" : "hover:bg-link-water")
      }
      tabIndex="-1"
    >
      {children}
      {active && <UilUp size="16" />}
      {!active && <UilDown size="16" />}
    </span>
  </button>
);

/**
 * Renders a button. Used in most pages and forms. It supports different styles and states, like: danger, secondary, disabled and loading.
 * @param {component} children - Children to render, probably just the button text or an Icon.
 * @param {bool} secondary - Flag to style the button as a secondary.
 * @param {bool} danger - Flag to style the button in red.
 * @param {bool} disabled - Flag to indicate the button is disabled.
 * @param {bool} loading - Flag to indicate the button is waiting to load. It disables the button.
 * @param {string} className - Classes to append to the button default classes.
 * @param {object} props - Extra props to set to the button.
 */
const Button = ({
  children,
  secondary,
  danger,
  disabled,
  dropzone,
  loading,
  noMinW,
  className = "",
  ...props
}) => (
  <button
    type={props.type || "button"}
    {...props}
    disabled={disabled || loading}
    className={
      "group focus:outline-none h-10 " +
      (noMinW ? "" : " min-w-36 ") +
      className
    }
  >
    <span
      className={
        "group-focus:ring focus:outline-none text-sm font-bold rounded px-4 h-10 inline-flex items-center justify-center " +
        (noMinW ? "" : " min-w-36 ") +
        (secondary
          ? "bg-link-water text-link hover:bg-link-lighter active:bg-link-light "
          : danger
          ? "bg-red-dark text-white hover:bg-red-darker active:bg-red-darker "
          : disabled
          ? "text-kasmir bg-link-water cursor-not-allowed "
          : dropzone
          ? "bg-red-dark text-white hover:bg-red-darker active:bg-red-darker border-dashed border-white border-2 "
          : loading
          ? "text-kasmir bg-animated cursor-not-allowed "
          : "bg-link text-white hover:bg-link-dark active:bg-link-darker ") +
        className
      }
      tabIndex="-1"
    >
      {children}
    </span>
  </button>
);

const TransparentButton = ({
  children,
  disabled,
  loading,
  className = "",
  ...props
}) => (
  <button
    type={props.type || "button"}
    {...props}
    disabled={disabled || loading}
    className={
      "group focus:outline-none h-10 " +
      className
    }
  >
    <span
      className={
        "group-focus:ring focus:outline-none hover:text-electric-indigo text-sm font-bold rounded h-10 inline-flex items-center justify-center px-0 bg-none border-none text-kasmir " +
        (disabled
          ? "text-kasmir bg-link-water cursor-not-allowed "
          : loading
          ? "text-kasmir bg-animated cursor-not-allowed " : "") +
        className
      }
      tabIndex="-1"
    >
      {children}
    </span>
  </button>
);

const OutlinedButton = ({ children, danger, secondary, className = "", ...props }) => (
  <button
    type={props.type || "button"}
    {...props}
    className={"group focus:outline-none h-6 " + className}
  >
    <span
      className={
        "group-focus:ring focus:outline-none text-sm font-bold rounded px-2 h-6 inline-flex items-center justify-center bg-white border " +
        (danger ? "text-red-dark border-red-dark " : "") +
        (secondary ?  "text-kasmir border-kasmir " : "") +
        (!danger && !secondary && "text-link border-link ") +
        (props.disabled ? "text-opacity-50 border-opacity-50 " : "") +
        className
      }
      tabIndex="-1"
    >
      {children}
    </span>
  </button>
);

const MergeButton = ({ children, className = "", ...props }) => (
  <Link
    {...props}
    className={"group focus:outline-none min-w-36 h-6 " + className}
  >
    <span
      className={
        "group-focus:ring focus:outline-none text-sm font-bold rounded px-2 h-6 inline-flex items-center justify-center bg-white text-green border border-green " +
        className
      }
      tabIndex="-1"
    >
      {children}
    </span>
  </Link>
);
const HubspotButton = ({ children, className = "", ...props }) => (
  <Link
    {...props}
    className={"group focus:outline-none min-w-36 h-6 " + className}
    target="_blank"
  >
   <span
      className={
        "group-focus:ring focus:outline-none text-sm font-bold rounded px-2 h-6 inline-flex items-center justify-center bg-white text-link border border-link " +
        className
      }
      tabIndex="-1"
    >
      {children}
    </span>
  </Link>
);

/**
 * Renders an anchor (link) as a button. Used in some links that design required to be a button.
 * It wrappers a react-router-dom Link component, so you can pass those same props.
 * @param {component} children - Children to render, probably just the button text or an Icon.
 * @param {bool} secondary - Flag to style the button as a secondary.
 * @param {bool} danger - Flag to style the button in red.
 * @param {string} className - Classes to append to the button default classes.
 * @param {object} props - Extra props to set to the button.
 */
const LinkButton = ({
  children,
  secondary,
  danger,
  className = "",
  ...props
}) => (
  <Link
    {...props}
    className={"group focus:outline-none min-w-36 h-10 " + className}
  >
    <span
      className={
        "group-focus:ring focus:outline-none text-sm font-bold rounded min-w-36 px-4 h-10 inline-flex items-center justify-center " +
        (secondary
          ? "bg-link-water text-link hover:bg-link-lighter active:bg-link-light "
          : danger
          ? "bg-red-dark text-white hover:bg-red-darker active:bg-red-darker "
          : props.disabled
          ? "text-kasmir bg-animated cursor-not-allowed "
          : "bg-link text-white hover:bg-link-dark active:bg-link-darker ") +
        className
      }
      tabIndex="-1"
    >
      {children}
    </span>
  </Link>
);

/**
 * Renders a button with an icon. Used in some recurrent buttons like edit, remove or add ones.
 * @param {component} children - Children to render, an Icon.
 * @param {bool} colored - Flag to style the button with an accent color.
 * @param {object} props - Extra props to set to the button.
 */
const IconBtn = ({
  children,
  colored,
  fullColored,
  danger,
  className,
  ...props
}) => (
  <button
    type={props.type || "button"}
    {...props}
    className={"group focus:outline-none ml-2 w-6 h-6 " + className}
  >
    <span
      className={
        "group-focus:ring focus:outline-none w-6 h-6 overflow-hidden bg-link-water text-sm rounded inline-flex items-center justify-center hover:bg-link-light active:bg-link-light " +
        (colored
          ? "text-link"
          : danger
          ? "bg-red-dark text-white hover:bg-red-darker active:bg-red-darker "
          : fullColored
          ? "text-white bg-link hover:bg-link-dark active:bg-link-darker "
          : "text-kasmir ") +
        className
      }
      tabIndex="-1"
    >
      {children}
    </span>
  </button>
);

/**
 * Renders a button with a Left arrow. Used to show buttons to go back to another page.
 * It wrappers a react-router-dom Link component, so you can pass those same props.
 * @param {string} text - Text to render.
 * @param {string} to - Url to link.
 */
const BackLinkBtn = ({ text, to }) => (
  <Link
    to={to}
    className="font-bold text-sm bg-link-lighter text-link hover:bg-link-light active:bg-link-light rounded inline-flex items-center py-1 px-2"
  >
    <UilLeft size="18" />
    {text}
  </Link>
);

/**
 * Renders a button with a camera icon.
 * @param {object} props - Extra props to set to the button.
 */
const PhotoBtn = (props) => (
  <IconBtn {...props}>
    <UilCamera size="16" />
  </IconBtn>
);

/**
 * Renders a button with a save icon.
 * @param {object} props - Extra props to set to the button.
 */
const SaveBtn = (props) => (
  <IconBtn {...props}>
    <UilSave size="16" />
  </IconBtn>
);

/**
 * Renders a button with a pen icon.
 * @param {object} props - Extra props to set to the button.
 */
const EditBtn = (props) => (
  <IconBtn {...props}>
    <UilPen size="16" />
  </IconBtn>
);

/**
 * Renders a button with a trash icon.
 * @param {object} props - Extra props to set to the button.
 */
const RemoveBtn = ({ className, ...props }) => (
  <IconBtn className={(props.disabled ? "text-opacity-50 border-opacity-50 " : "") + className} {...props}>
    <UilTrash size="16" />
  </IconBtn>
);

/**
 * Renders a button with a times icon.
 * @param {object} props - Extra props to set to the button.
 */
const CancelBtn = (props) => (
  <IconBtn {...props}>
    <UilTimes size="16" />
  </IconBtn>
);

/**
 * Renders a button with a plus icon.
 * @param {object} props - Extra props to set to the button.
 */
const AddBtn = (props) => (
  <IconBtn {...props}>
    <UilPlus size="20" />
  </IconBtn>
);

/**
 * Renders a button with a sync icon.
 * @param {object} props - Extra props to set to the button.
 */
const RetryBtn = (props) => (
  <IconBtn {...props}>
    <UilSync size="18" />
  </IconBtn>
);

/**
 * Renders a link with a download icon.
 * @param {bool} colored - Flag to style the button with an accent color.
 * @param {object} props - Extra props to set to the button.
 */
const DownloadBtn = ({ colored, ...props }) => (
  <a {...props} className="group focus:outline-none ml-2 w-6 h-6">
    <span
      className={
        "group-focus:ring focus:outline-none w-6 h-6 overflow-hidden bg-link-water text-sm rounded inline-flex items-center justify-center hover:bg-link-light active:bg-link-light " +
        (props.colored ? "text-link" : "text-kasmir")
      }
      tabIndex="-1"
    >
      <UilImport size="18" />
    </span>
  </a>
);

/**
 * Renders a link with a link icon.
 * @param {bool} colored - Flag to style the button with an accent color.
 * @param {object} props - Extra props to set to the button.
 */
const AnchorBtn = ({ colored, ...props }) => (
  <a {...props} className="group focus:outline-none ml-2 w-6 h-6">
    <span
      className={
        "group-focus:ring focus:outline-none w-6 h-6 overflow-hidden bg-link-water text-sm rounded inline-flex items-center justify-center hover:bg-link-light active:bg-link-light " +
        (props.colored ? "text-link" : "text-kasmir")
      }
      tabIndex="-1"
    >
      <UilLink size="18" />
    </span>
  </a>
);

/**
 * Renders a button with required text.
 * @param {object} props - Extra props to set to the button.
 */
const RequiredBtn = ({ colored, ...props }) => (
  <button
    type={props.type || "button"}
    {...props}
    className="group focus:outline-none ml-2 h-6"
  >
    <span
      className={
        "group-focus:ring focus:outline-none px-2 h-6 overflow-hidden bg-link-water text-sm rounded inline-flex items-center justify-center hover:bg-link-light active:bg-link-light " +
        (colored ? "text-link font-bold" : "text-kasmir")
      }
      tabIndex="-1"
    >
      {$.required_button}
    </span>
  </button>
);

/**
 * Renders a button with a check. Used in some parts to indicate an item is selected.
 * @param {object} props - Extra props to set to the button.
 */
const SelectedBtn = (props) => (
  <button
    type="button"
    {...props}
    className="group focus:outline-none ml-2 w-6 h-6"
  >
    <span
      className="text-link group-focus:ring focus:outline-none w-6 h-6 overflow-hidden bg-transparent text-sm rounded inline-flex items-center justify-center hover:bg-link-water active:bg-link-water"
      tabIndex="-1"
    >
      <UilCheck className="text-link" size="20" />
    </span>
  </button>
);

export {
  Button,
  PhotoBtn,
  EditBtn,
  RemoveBtn,
  AddBtn,
  RequiredBtn,
  Toggler,
  BackLinkBtn,
  LinkButton,
  CloseBtn,
  DownloadBtn,
  CancelBtn,
  SaveBtn,
  RetryBtn,
  SelectedBtn,
  IconBtn,
  OutlinedButton,
  AnchorBtn,
  MergeButton,
  HubspotButton,
  TransparentButton
};
