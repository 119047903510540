import React from "react";
import { useParams } from "react-router-dom";
import Section from "components/Section";
import { getTalentQualificationsV2 } from "utils/adminApi";
import AboutTalent from "./AboutTalent";
import Qualifications from "./Qualifications";
import Preferences from "./Preferences";
import Onboarding from "./Onboarding";
import useQualifications from "hooks/useQualifications";
import useCachedFetch from "hooks/useCachedFetch";

const Overview = ({ data, setData }) => {
  const { id } = useParams();
  const { qualifications } = useQualifications(true);

  const talentQualifications = useCachedFetch(
    "admin_talent_qualifications_v2",
    getTalentQualificationsV2,
    id,
    { page_size: 9999 }
  );

  return (
    <Section id="admin_talent_overview" className="flex mt-px h-full overflow-y-auto">
      <div className="bg-white flex-1 mr-2 h-full flex flex-col">
        <Qualifications
          talentQualifications={talentQualifications}
          qualificationsOptions={qualifications.qualificationsArray}
          user={id}
        />
      </div>
      <div className="w-1/3 h-full overflow-y-auto">
        {data && (
          <>
            <Onboarding user={id} talentData={data} setTalentData={setData} />
            <Preferences talentData={data} setTalentData={setData} />
            <AboutTalent talentData={data} />
          </>
        )}
      </div>
    </Section>
  );
};

export default Overview;
