import React from "react";
import { ErrorInfoIcon } from "../icons";

const Error = ({ msg }) => {
  return (
    <div className="flex items-center py-px gap-2">
      <ErrorInfoIcon />
      <span className="text-red text-sm font-medium">{msg}</span>
    </div>
  )
};

export default Error;