import React from "react";
import clsx from "clsx";

const DesignIcon = ({
  isSelected
}) => {
  return (
    <div className={clsx('rounded-lg p-2', isSelected ? 'bg-electric-indigo' : 'bg-lightest-grey')}>
      <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_951_1763)">
          <path d="M17.8203 12.7033C17.9915 14.6316 16.477 16.2501 14.5833 16.2501H10.2738C9.52525 16.2501 8.99442 15.5286 9.21758 14.8147C9.76142 13.0716 11.3149 10.3026 13.8109 9.82272C15.7122 9.45655 17.6492 10.775 17.8203 12.7033ZM17.8572 8.73614C18.6588 9.34064 19.2427 10.1542 19.6024 11.0556L25.733 4.46347C26.7546 3.44297 26.7567 1.78764 25.7362 0.766052C24.7168 -0.254447 23.0637 -0.255531 22.0432 0.763886C22.0085 0.798552 16.0057 7.81964 16.0057 7.81964C16.6655 8.00922 17.2971 8.31364 17.8572 8.73614ZM18.5797 16.6575C17.5571 17.7755 16.1 18.4179 14.5844 18.4179H10.2749C9.23708 18.4179 8.25125 17.9196 7.637 17.0854C7.02275 16.2512 6.84075 15.1614 7.1495 14.1712C7.93925 11.6449 9.98892 8.50647 13.1782 7.7633L17.9319 2.16897H5.91667C2.92992 2.1668 0.5 4.59672 0.5 7.58347V16.2501C0.5 19.2369 2.92992 21.6668 5.91667 21.6668H12.4167V23.8335H9.16667C8.56867 23.8335 8.08333 24.3177 8.08333 24.9168C8.08333 25.5159 8.56867 26.0001 9.16667 26.0001H17.8333C18.4324 26.0001 18.9167 25.5159 18.9167 24.9168C18.9167 24.3177 18.4324 23.8335 17.8333 23.8335H14.5833V21.6668H21.0833C24.0701 21.6668 26.5 19.2369 26.5 16.2501V6.80889L19.9014 13.9004C19.7281 14.9187 19.2861 15.8829 18.5797 16.6553V16.6575Z" fill={isSelected ? '#fff ': '#4C5167'} />
        </g>
        <defs>
          <clipPath id="clip0_951_1763">
            <rect width="26" height="26" fill={isSelected ? '#3049c5': '#fff'} transform="translate(0.5)"/>
          </clipPath>
        </defs>
      </svg>
    </div>
  )
}

export default DesignIcon;