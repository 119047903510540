import React from "react";
import { admin_candidate_projects as $ } from "strings";
import { Link } from "react-router-dom";
import { toFormat } from "utils/date";
import { statusOptions } from "./constants";
import { stageOptions } from "utils/globalConsts";

const CandidateProject = ({ project }) => {
  const {
    name,
    project_id,
    stage,
    owner,
    pay_rate,
    updated_at,
    status,
    est_hours,
    client_updated_at
  } = project;

  // pull in estimated hours / rate if possible
  return (
    <div id="candidate_project">
      <div className="w-full flex">
        <div className="w-1/2">
          <div className="text-sm text-midnight font-bold">
            <Link className="inline-block text-link" to={`/admin/project/${project_id}`}>
              {name}
            </Link>
          </div>
        </div>
      </div>
      <div className="w-full flex">
        <div className="w-1/2">
          <div className="text-sm text-midnight font-bold">
            {$.stage_title}: {stageOptions.find(o => o.value === stage).label || $.empty_value}
          </div>
        </div>
        <div className="w-1/2">
          <div className="text-sm text-midnight font-bold">
            {$.candidate_status_title}: {statusOptions.find(o => o.value === status).label || $.empty_value}
          </div>
        </div>
      </div>
      <div className="w-full flex">
        <div className="w-1/2">
          <div className="text-sm text-midnight font-bold">
            {$.project_owner_title}: {owner.length === 0 && $.empty_value} {owner.map((o, i) => <span key={i}>{o}{i !== owner.length - 1 && ', '}</span>)}
          </div>
        </div>
        <div className="w-1/2">
          <div className="text-sm text-midnight font-bold">
            {$.est_hours_title}: {est_hours || $.empty_value}
          </div>
        </div>
      </div>
      <div className="w-full flex">
        <div className="w-1/2">
          <div className="text-sm text-midnight font-bold">
            {$.pay_rate_title}: {pay_rate ? `$${pay_rate}` : $.empty_value}
          </div>
        </div>
        <div className="w-1/2">
          <div className="text-sm text-midnight font-bold">
            {$.candidate_updated_title}: {client_updated_at ? toFormat(new Date(client_updated_at), "MM/dd/yyyy") : $.empty_value}
          </div>
        </div>
      </div>
      <div className="w-full flex">
        <div className="w-1/2">
          <div className="text-sm text-midnight font-bold">
          { $.project_updated_title}: {updated_at ? toFormat(new Date(updated_at), "MM/dd/yyyy") : $.empty_value}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CandidateProject;
